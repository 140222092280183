import React from 'react';
import './StatsHighlight.css';
import ChartComponent from './ChartComponent';

const StatsHighlight = ({ stats, locationInfo, permitData, listingsData, activePendingData, marketOverviewData }) => {
  // Log the incoming props to debug
  console.log('StatsHighlight Props:', { stats, locationInfo, permitData, listingsData, activePendingData, marketOverviewData });

  // Extract statistics from listingsData
  const listingsStats = listingsData?.listings_stats || {};
  console.log('Extracted Listings Stats:', listingsStats);

  // Use provided stats or fall back to default stats
  const displayStats = [
    { 
      title: 'Median Listing Price', 
      value: listingsStats.median_listings_price ? `$${listingsStats.median_listings_price.toLocaleString()}` : 'N/A', 
      change: listingsStats.median_price_change ? `${listingsStats.median_price_change.toFixed(1)}%` : 'N/A', 
      period: 'last 12 months' 
    },
    { 
      title: 'Days on Market', 
      value: listingsStats.average_days_on_market_inactive ? listingsStats.average_days_on_market_inactive.toFixed(1) : 'N/A', 
      change: listingsStats.avg_days_on_market_change ? `${listingsStats.avg_days_on_market_change.toFixed(1)}%` : 'N/A', 
      period: 'last 12 months' 
    },
    { 
      title: 'Active Listings', 
      value: listingsStats.active_listings_count || 'N/A', 
      change: '',  // No change calculation for active listings
      period: 'current count' 
    },
    { 
      title: 'Price per Sq Ft', 
      value: listingsStats.average_price_per_sqft ? `$${listingsStats.average_price_per_sqft.toFixed(2)}` : 'N/A', 
      change: listingsStats.avg_price_per_sqft_change ? `${listingsStats.avg_price_per_sqft_change.toFixed(1)}%` : 'N/A', 
      period: 'last 12 months' 
    },
    { 
      title: 'New Listings', 
      value: listingsStats.newly_listed_count || 'N/A', 
      change: listingsStats.newly_listed_change ? 
        `${listingsStats.newly_listed_change > 0 ? '+' : ''}${listingsStats.newly_listed_change.toFixed(1)}%` : 'N/A',
      period: 'last 30 days' 
    },
    { 
      title: 'Removed Listings', 
      value: listingsStats.listings_removed_count || 'N/A', 
      change: listingsStats.listings_removed_change ? 
        `${listingsStats.listings_removed_change > 0 ? '+' : ''}${listingsStats.listings_removed_change.toFixed(1)}%` : 'N/A',
      period: 'last 30 days' 
    }
  ];

  // Log the displayStats to debug
  console.log('Display Stats:', displayStats);

  const getChangeDescription = (statTitle) => {
    switch (statTitle) {
      case 'Median Listing Price':
      case 'Days on Market':
      case 'Price per Sq Ft':
        return 'Change is relative to previous 12 months (from 1 month ago)';
      case 'New Listings':
      case 'Removed Listings':
        return 'Change compared to previous 30 day period';
      default:
        return '';
    }
  };

  return (
    <div className="stats-highlight-wrapper">
      <h1 className="community-header">
        {(locationInfo?.location_name || 'Community Overview').toUpperCase()}
      </h1>
      <div className="community-subtitle">
        {locationInfo && (
          <>ZIP {locationInfo.zip_code.toString().padStart(5, '0')} • {locationInfo.county_name} County, {locationInfo.state_abbreviation}</>
        )}
      </div>
      
      <div className="area-overview">
        <div 
          dangerouslySetInnerHTML={{ 
            __html: (marketOverviewData && marketOverviewData.chart_description) 
              ? marketOverviewData.chart_description.replace(/&lt;/g, '<').replace(/&gt;/g, '>') 
              : 'No market overview retrieved'
          }} 
        />
      </div>

      <div className="stats-highlight-container">
        <h2 className="section-title">Listings Details</h2>
        <div className="listings-content-row">
          <div className="stats-grid listings-stats">
            {displayStats.map((stat, index) => (
              <div key={index} className="stat-card">
                <div className="stat-content">
                  <h3 className="stat-title">{stat.title}</h3>
                  <div className="stat-value-container">
                    <span className="stat-value">{stat.value}</span>
                    <span 
                      className={`stat-change ${parseFloat(stat.change) > 0 ? 'positive' : 'negative'}`}
                      title={getChangeDescription(stat.title)}
                    >
                      {stat.change}
                    </span>
                  </div>
                  <span className="stat-period">{stat.period}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="charts-column">
            {listingsData?.scatter_plot && (
              <div className="stats-chart-container">
                <h3 className="chart-title">Price vs Square Footage of listings within 5 miles</h3>
                <div className="stats-chart">
                  <ChartComponent 
                    chartData={listingsData.scatter_plot}
                  />
                </div>
              </div>
            )}
            {activePendingData?.chart_data && (
              <div className="stats-chart-container">
                <h3 className="chart-title">Active and Pending Listings for Zip Code</h3>
                <div className="stats-chart">
                  <ChartComponent 
                    chartData={activePendingData.chart_data}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="stats-highlight-container permits-section">
        <h2 className="section-title">County Permits Details</h2>
        <div className="stats-content-wrapper">
          <div className="permits-content-row">
            <div className="stats-grid permits-grid">
              <div className="stat-card">
                <div className="stat-content">
                  <h3 className="stat-title">Total Permits</h3>
                  <div className="stat-value-container text-center">
                    <span className="stat-value">
                      {permitData?.permit_stats?.total_permits_12m ? permitData.permit_stats.total_permits_12m.toLocaleString() : 'N/A'}
                    </span>
                    {permitData?.permit_stats?.total_permits_change && (
                      <span 
                        className={`stat-change ${permitData.permit_stats.total_permits_change > 0 ? 'positive' : 'negative'}`}
                        title="Change in total permits compared to previous 12-month period"
                      >
                        {permitData.permit_stats.total_permits_change > 0 ? '+' : ''}
                        {permitData.permit_stats.total_permits_change.toFixed(1)}%
                      </span>
                    )}
                  </div>
                  <span className="stat-period">Last 12 Months</span>
                </div>
              </div>
              <div className="stat-card">
                <div className="stat-content">
                  <h3 className="stat-title">Average Monthly</h3>
                  <div className="stat-value-container text-center">
                    <span className="stat-value">
                      {permitData?.permit_stats?.avg_monthly_permits_12m ? permitData.permit_stats.avg_monthly_permits_12m.toLocaleString() : 'N/A'}
                    </span>
                    <span className="stat-value-sub">units</span>
                  </div>
                  <span className="stat-period">Last 12 months</span>
                </div>
              </div>
              <div className="stat-card">
                <div className="stat-content">
                  <h3 className="stat-title">Recent Month Permits (Units)</h3>
                  <div className="stat-value-container text-center">
                    <span className="stat-value">
                      {permitData?.permit_stats?.recent_month_permits ? permitData.permit_stats.recent_month_permits.toLocaleString() : 'N/A'}
                    </span>
                    {permitData?.permit_stats?.recent_month_change && (
                      <span 
                        className={`stat-change ${permitData.permit_stats.recent_month_change > 0 ? 'positive' : 'negative'}`}
                        title="Change compared to previous month"
                      >
                        {permitData.permit_stats.recent_month_change > 0 ? '+' : ''}
                        {permitData.permit_stats.recent_month_change.toFixed(1)}%
                      </span>
                    )}
                  </div>
                  <span className="stat-period">{permitData?.permit_stats?.recent_month || 'N/A'}</span>
                </div>
              </div>
              <div className="stat-card">
                <div className="stat-content">
                  <h3 className="stat-title">Permits per 1000 People (Units)</h3>
                  <div className="stat-value-container">
                    <span className="stat-value">
                      {permitData?.permit_stats?.permits_per_1000 ? permitData.permit_stats.permits_per_1000.toLocaleString() : 'N/A'}
                    </span>
                    <span className="stat-value-sub">Units</span>
                  </div>
                  <span className="stat-period">population ratio</span>
                </div>
              </div>
            </div>
            {permitData?.chart_js_json && (
              <div className="stats-chart-container permits-chart">
                <h3 className="chart-title">Building Permits Over Time</h3>
                <div className="stats-chart">
                  <ChartComponent 
                    chartData={permitData.chart_js_json}
                  />
                </div>
              </div>
            )}
          </div>
          {permitData?.rows && permitData?.headers && (
            <div className="permits-table-section">
              <h3 className="table-title">Building Permits History</h3>
              <table className="permits-table">
                <thead>
                  <tr>
                    {permitData.headers.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {permitData.rows.map((row, index) => (
                    <tr key={index}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>
                          {typeof cell === 'number' ? cell.toLocaleString() : cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StatsHighlight; 