import React, { useState, useRef, useEffect, useCallback } from 'react';
import api from '../api';
import './ChatBox.css';

const ChatBox = ({ userId, locations }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const abortControllerRef = useRef(null);
  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [showHistory, setShowHistory] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  const [activeLocations, setActiveLocations] = useState([]);
  const [activeZipCodes, setActiveZipCodes] = useState([]);
  const [zipInput, setZipInput] = useState('');
  const [showZipInput, setShowZipInput] = useState(false);
  const [zipLocations, setZipLocations] = useState({});
  const [locationListings, setLocationListings] = useState({});
  const [listingsFilters, setListingsFilters] = useState(null);
  const [offMarketListings, setOffMarketListings] = useState({});

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const fetchChatHistory = useCallback(async () => {
    try {
      const response = await api.get(`/api/chat-history?user_id=${userId}`);
      setChatHistory(response.data.threads);
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchChatHistory();
    }
  }, [userId, fetchChatHistory]);

  const loadChatThread = (thread) => {
    const formattedMessages = thread.messages.map(msg => ({
      text: msg.message,
      sender: msg.from_user ? 'user' : 'bot'
    }));

    setMessages(formattedMessages);
    setCurrentThreadId(thread.thread_id);
    setShowHistory(false);
  };

  const handleNewChat = () => {
    setMessages([]);
    setInputMessage('');
    setIsLoading(false);
    setCurrentThreadId(null);
    
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    const userMessage = { text: inputMessage, sender: 'user' };
    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsLoading(true);

    // Add logging to check the data
    console.log('Active Locations:', activeLocations);
    console.log('Off Market Listings State:', offMarketListings);
    
    const requestData = {
      message: inputMessage,
      claude_thread_id: currentThreadId,
      user_id: userId,
      location_ids: activeLocations,
      zip_codes: activeZipCodes,
      listings_data: Object.fromEntries(
        activeLocations.map(locId => [
          locId, 
          locationListings[locId] || []
        ])
      ),
      off_market_listings_data: Object.fromEntries(
        activeLocations.map(locId => [
          locId, 
          offMarketListings[locId] || []
        ])
      )
    };

    // Log the final request data
    console.log('Request Data:', requestData);

    try {
      const response = await api.post('/api/chat', requestData);
      
      if (response.data.claude_thread_id) {
        setCurrentThreadId(response.data.claude_thread_id);
      }
      
      const formattedResponse = response.data.response
        .replace(/\n{3,}/g, '\n\n')
        .replace(/\n-/g, '\n• ')
        .replace(/(?:\n\n+)([•-])/g, '\n$1')
        .replace(/\n/g, '<br>');
      
      const botMessage = { text: formattedResponse, sender: 'bot' };
      setMessages(prev => [...prev, botMessage]);
      
    } catch (error) {
      console.error('Error getting response:', error);
      const errorMessage = { 
        text: 'Sorry, I encountered an error. Please try again.', 
        sender: 'bot' 
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSuggestedQuestion = async (question) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();
    
    setInputMessage('');
    const userMessage = { text: question, sender: 'user' };
    setMessages(prev => [...prev, userMessage]);
    setIsLoading(true);

    try {
      const response = await api.post('/api/chat', {
        message: question,
        claude_thread_id: currentThreadId,
        user_id: userId,
        location_ids: activeLocations,
        zip_codes: activeZipCodes,
        listings_data: Object.fromEntries(
          activeLocations.map(locId => [
            locId, 
            locationListings[locId] || []
          ])
        ),
        off_market_listings_data: Object.fromEntries(
          activeLocations.map(locId => [
            locId, 
            offMarketListings[locId] || []
          ])
        )
      });

      if (response.data.claude_thread_id) {
        setCurrentThreadId(response.data.claude_thread_id);
      }

      const botMessage = { text: response.data.response, sender: 'bot' };
      setMessages(prev => [...prev, botMessage]);
      
    } catch (error) {
      console.error('Error getting response:', error);
      const errorMessage = { 
        text: 'Sorry, I encountered an error. Please try again.', 
        sender: 'bot' 
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isExpanded && isOpen) {
      document.body.classList.add('chat-expanded');
    } else {
      document.body.classList.remove('chat-expanded');
    }
    
    return () => {
      document.body.classList.remove('chat-expanded');
    };
  }, [isExpanded, isOpen]);

  const fetchFiltersForLocation = async (locationId) => {
    try {
      const response = await api.get('/api/get-filters', {
        params: { location_id: locationId }
      });
      
      if (response.data.filters) {
        // Convert backend filters to frontend format
        return {
          priceRange: { 
            min: response.data.filters.priceMin || '', 
            max: response.data.filters.priceMax || '' 
          },
          bedroomCount: { 
            min: response.data.filters.bedMin || '', 
            max: response.data.filters.bedMax || '' 
          },
          squareFootage: { 
            min: response.data.filters.sqftMin || '', 
            max: response.data.filters.sqftMax || '' 
          },
          homeType: response.data.filters.homeType || 'both',
          yearBuilt: { 
            min: response.data.filters.yearMin || '', 
            max: response.data.filters.yearMax || '' 
          }
        };
      }
      // Return default filters if none saved
      return {
        priceRange: { min: '250000', max: '800000' },
        bedroomCount: { min: '2', max: '5' },
        squareFootage: { min: '1500', max: '4000' },
        homeType: 'single-family',
        yearBuilt: { min: '2021', max: '' }
      };
    } catch (error) {
      console.error('Error fetching filters:', error);
      return null;
    }
  };

  const handleLocationSelect = async (locationId) => {
    if (!locationId) return;
    
    setActiveLocations(prev => {
      if (prev.includes(locationId)) {
        // When removing a location, also remove its off-market listings data
        setOffMarketListings(prev => {
          const newState = { ...prev };
          delete newState[locationId];
          return newState;
        });
        // Also remove its regular listings data
        setLocationListings(prev => {
          const newState = { ...prev };
          delete newState[locationId];
          return newState;
        });
        return prev.filter(id => id !== locationId);
      }
      // Fetch filters when adding new location
      fetchFiltersForLocation(locationId).then(filters => {
        if (filters) {
          setListingsFilters(filters);
        }
      });
      return [...prev, locationId];
    });
  };

  const handleZipCodeAdd = async () => {
    if (zipInput.trim() && /^\d{5}$/.test(zipInput)) {
      try {
        const response = await api.get(`/api/zip-info/${zipInput}`);
        setZipLocations(prev => ({
          ...prev,
          [zipInput]: {
            city: response.data.city,
            state: response.data.state_abbreviation
          }
        }));
        setActiveZipCodes(prev => {
          if (!prev.includes(zipInput)) {
            return [...prev, zipInput];
          }
          return prev;
        });
        setZipInput('');
        setShowZipInput(false);
      } catch (error) {
        console.error('Error fetching ZIP info:', error);
        // Optionally show an error message to the user
      }
    }
  };

  const handleZipRemove = (zip) => {
    setActiveZipCodes(prev => prev.filter(z => z !== zip));
  };

  const handleApplyLocations = () => {
    setShowLocationSelector(false);
  };

  useEffect(() => {
    const fetchListings = async (locationId) => {
      if (!locationId) return;
      
      try {
        // First get location info
        const locationResponse = await api.get('/api/get-location-details', {
          params: { locationId },
        });
        const locationInfo = locationResponse.data;

        // Then get listings
        const response = await api.get('/api/get-listings', {
          params: {
            locationId,
            latitude: locationInfo.latitude,
            longitude: locationInfo.longitude,
            yearsOld: 3
          }
        });

        // Filter the listings
        const filtered = response.data.listings.filter((listing) => {
          const listingPrice = Number(listing.price);
          const listingSqFt = Number(listing.square_footage);
          const listingBedrooms = Number(listing.bedrooms);
          const listingYearBuilt = listing.year_built ? Number(listing.year_built) : null;

          // Extract filter values with fallback to undefined
          const priceMin = Number(listingsFilters?.priceRange?.min) || undefined;
          const priceMax = Number(listingsFilters?.priceRange?.max) || undefined;
          const bedroomsMin = Number(listingsFilters?.bedroomCount?.min) || undefined;
          const bedroomsMax = Number(listingsFilters?.bedroomCount?.max) || undefined;
          const sqftMin = Number(listingsFilters?.squareFootage?.min) || undefined;
          const sqftMax = Number(listingsFilters?.squareFootage?.max) || undefined;
          const yearMin = Number(listingsFilters?.yearBuilt?.min) || undefined;
          const yearMax = Number(listingsFilters?.yearBuilt?.max) || undefined;

          const meetsPrice =
            (!priceMin || listingPrice >= priceMin) &&
            (!priceMax || listingPrice <= priceMax);

          const meetsBedrooms =
            (!bedroomsMin || listingBedrooms >= bedroomsMin) &&
            (!bedroomsMax || listingBedrooms <= bedroomsMax);

          const meetsSqft =
            (!sqftMin || listingSqFt >= sqftMin) &&
            (!sqftMax || listingSqFt <= sqftMax);

          const meetsHomeType =
            !listingsFilters?.homeType ||
            listingsFilters.homeType === 'both' ||
            (listingsFilters.homeType === 'single-family' &&
              listing.property_type?.toLowerCase() === 'single family') ||
            (listingsFilters.homeType === 'attached' &&
              listing.property_type?.toLowerCase() !== 'single family');

          const meetsYearBuilt =
            (!yearMin || (listingYearBuilt !== null && listingYearBuilt >= yearMin)) &&
            (!yearMax || (listingYearBuilt !== null && listingYearBuilt <= yearMax));

          return (
            meetsPrice &&
            meetsBedrooms &&
            meetsSqft &&
            meetsHomeType &&
            meetsYearBuilt
          );
        });

        setLocationListings(prev => ({
          ...prev,
          [locationId]: filtered
        }));
      } catch (err) {
        console.error('Error fetching listings:', err);
      }
    };

    // Fetch listings for each active location
    activeLocations.forEach(locationId => {
      if (!locationListings[locationId]) {
        fetchListings(locationId);
      }
    });
  }, [activeLocations, listingsFilters, locationListings]);

  useEffect(() => {
    const fetchOffMarketListings = async (locationId) => {
      if (!locationId) return;
      
      try {
        // First get location info
        const locationResponse = await api.get('/api/get-location-details', {
          params: { locationId },
        });
        const locationInfo = locationResponse.data;

        // Then get off-market listings
        const response = await api.get('/api/get-attom-listings', {
          params: {
            locationId,
            latitude: locationInfo.latitude,
            longitude: locationInfo.longitude
          }
        });
        
        // Filter off-market listings using only price range
        const filteredListings = response.data.listings.filter(listing => {
          // Extract price filter values with fallback to undefined
          const priceMin = Number(listingsFilters?.priceRange?.min) || undefined;
          const priceMax = Number(listingsFilters?.priceRange?.max) || undefined;
          const listingPrice = Number(listing.sale_amount);

          // Check only price condition
          const meetsPrice =
            (!priceMin || listingPrice >= priceMin) &&
            (!priceMax || listingPrice <= priceMax);

          return meetsPrice;
        });

        setOffMarketListings(prev => ({
          ...prev,
          [locationId]: filteredListings
        }));
      } catch (err) {
        console.error('Error fetching off-market listings:', err);
      }
    };

    // Fetch off-market listings for each active location
    activeLocations.forEach(locationId => {
      if (!offMarketListings[locationId]) {
        fetchOffMarketListings(locationId);
      }
    });
  }, [activeLocations, listingsFilters, offMarketListings]);

  return (
    <div className={`chatbox-container ${isOpen ? 'open' : ''}`}>
      <button 
        className="chat-toggle-button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 'Close chat' : 'Home Building Advisor'}
      </button>
      
      {isOpen && (
        <div className={`chat-window ${isExpanded ? 'expanded' : ''}`}>
          <div className="chat-header">
            <div className="left-controls">
              <button 
                className="new-chat-button"
                onClick={handleNewChat}
              >
                New Chat
              </button>
              <button 
                className="new-chat-button"
                onClick={() => {
                  setShowHistory(!showHistory);
                  if (!showHistory) {
                    fetchChatHistory();
                  }
                }}
              >
                History
              </button>
            </div>
            <h3>Home Building Advisor</h3>
            <div className="window-controls">
              <button 
                className="window-control-button live-data-button"
                onClick={() => setShowLocationSelector(!showLocationSelector)}
                style={{ backgroundColor: '#E26313', color: 'white', fontSize: '.9em' }}
              >
                Live Data
              </button>
              <button 
                className="window-control-button square-button"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? '⤢' : '⤢'}
              </button>
              <button 
                className="window-control-button square-button close-button"
                onClick={() => setIsOpen(false)}
              >
                ✕
              </button>
            </div>
          </div>
          
          {showLocationSelector && (
            <div className="location-selector">
              <div className="location-selector-content">
                <h4>Select Markets</h4>
                <div className="selected-locations">
                  {activeLocations.map(locId => {
                    const location = locations.find(l => String(l.id) === String(locId));
                    return location && (
                      <div key={locId} className="selected-location-tag">
                        {location.name}
                        <button 
                          onClick={() => handleLocationSelect(String(locId))}
                          className="remove-location"
                        >
                          ×
                        </button>
                      </div>
                    );
                  })}
                  {activeZipCodes.map(zip => (
                    <div key={zip} className="selected-location-tag zip-tag">
                      {zipLocations[zip] ? 
                        `${zipLocations[zip].city}, ${zipLocations[zip].state} (${zip})` : 
                        `ZIP: ${zip}`}
                      <button 
                        onClick={() => handleZipRemove(zip)}
                        className="remove-location"
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
                
                <div className="location-inputs">
                  <select 
                    value=""
                    onChange={(e) => handleLocationSelect(e.target.value)}
                    className="chat-location-select"
                  >
                    <option value="" disabled>
                      {activeLocations.length === 0 && activeZipCodes.length === 0 ? 'Select a market' : 'Select another market'}
                    </option>
                    {locations
                      .filter(loc => !activeLocations.includes(String(loc.id)))
                      .map(location => (
                        <option key={location.id} value={String(location.id)}>
                          {location.name}
                        </option>
                    ))}
                  </select>

                  {showZipInput ? (
                    <div className="zip-input-container">
                      <input
                        type="text"
                        value={zipInput}
                        onChange={(e) => setZipInput(e.target.value)}
                        placeholder="Enter ZIP code"
                        className="zip-input"
                        maxLength={5}
                      />
                      <button 
                        onClick={handleZipCodeAdd}
                        className="zip-add-button"
                        disabled={!zipInput.trim() || !/^\d{5}$/.test(zipInput)}
                      >
                        Add
                      </button>
                    </div>
                  ) : (
                    <button 
                      onClick={() => setShowZipInput(true)}
                      className="add-zip-button"
                    >
                      + Add ZIP code
                    </button>
                  )}
                </div>

                <div className="location-selector-footer">
                  <button 
                    className="apply-button"
                    onClick={handleApplyLocations}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          )}
          
          <div className="chat-content">
            {showHistory && (
              <div className="history-sidebar">
                {chatHistory.map((thread) => (
                  <div 
                    key={thread.thread_id} 
                    className="history-item"
                    onClick={() => loadChatThread(thread)}
                  >
                    <p>{thread.first_message}</p>
                    <small>{new Date(thread.last_message_time).toLocaleString()}</small>
                  </div>
                ))}
              </div>
            )}
            
            <div className={`messages-container ${showHistory ? 'with-history' : ''}`}>
              {messages.length === 0 && (
                <div className="welcome-message">
                  <p className="welcome-intro">Ask a question or try these common topics:</p>
                  {[
                    "What can you do for me?",
                    "What are the 15 functions of Home Building?",
                    "Help me price my lots.",
                    "Help me with hiring and interview prep",
                    "How do I improve my marketing?",
                    "Clients think my prices are too high - what should I do?",
                    ...(isExpanded ? [
                      "How do I improve my foot traffic?",
                      "What are the essentials of land development?",
                      "Help me with my inventory home strategy.",
                      "What makes a great floorplan?"
                    ] : [])
                  ].map((question, index) => (
                    <button
                      key={index}
                      className="suggested-question"
                      onClick={() => handleSuggestedQuestion(question)}
                    >
                      {question}
                    </button>
                  ))}
                  {!isExpanded && (
                    <p className="expand-hint">
                      Click expand ⤢ in the top right for more suggestions
                    </p>
                  )}
                </div>
              )}
              {messages.map((message, index) => (
                <div 
                  key={index} 
                  className={`message ${message.sender}`}
                >
                  {message.sender === 'bot' ? (
                    <div 
                      dangerouslySetInnerHTML={{ 
                        __html: message.text
                          .replace(/\n/g, '<br>')
                          .replace(/•/g, '&bull;') 
                      }} 
                    />
                  ) : (
                    message.text
                  )}
                </div>
              ))}
              {isLoading && (
                <div className="message bot loading">
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>

          <form onSubmit={handleSubmit} className="chat-input-form">
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder="Ask a question..."
              disabled={isLoading}
            />
            <button type="submit" disabled={isLoading}>
              Send
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ChatBox; 