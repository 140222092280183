// src/components/SignUp.js

import React, { useState } from 'react';
import './SignUp.css';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import api from '../api'; 
import { useNavigate, Link } from 'react-router-dom';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');  // Single 'name' field
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const navigate = useNavigate();

  // Handle sign-up
  const handleSignUp = async (e) => {
    e.preventDefault();

    if (password1 !== password2) {
      alert("Passwords don't match.");
      return;
    }

    try {
      // Create user with Firebase
      const userCredential = await createUserWithEmailAndPassword(auth, email, password1);
      const user = userCredential.user;
      
      // Update user profile with display name
      await updateProfile(user, { displayName: name });

      // Get Firebase ID token
      const idToken = await user.getIdToken();

      // Send ID token to backend to create/update user in DB
      const response = await api.post('/firebase-login', { idToken, name }, { withCredentials: true });

      if (response.data.success) {
        alert("Your account has been created!"); // Custom success message
        // Redirect to login page instead of home
        navigate('/login');
      } else {
        alert(response.data.message); // Show error message
      }

    } catch (error) {
      console.error('Sign-Up Error:', error);
      if (error.code === 'auth/email-already-in-use') {
        alert('Email already in use.');
      } else if (error.code === 'auth/invalid-email') {
        alert('Invalid email address.');
      } else if (error.code === 'auth/weak-password') {
        alert('Password is too weak.');
      } else {
        alert('An error occurred during sign-up. Please try again.');
      }
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-form">
        <h3 align="center">Sign Up</h3>
        <form onSubmit={handleSignUp}>
          <div className="form-group mb-3">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              placeholder="Enter full name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="password1">Password</label>
            <input
              type="password"
              className="form-control"
              id="password1"
              name="password1"
              placeholder="Enter password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="password2">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              id="password2"
              name="password2"
              placeholder="Confirm password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">Sign Up</button>
        </form>
        <p className="mt-3 text-center">
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;
