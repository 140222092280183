import React from 'react';
import DataTable from 'react-data-table-component';

/**
 * ListingsDataTable
 *
 * Displays the main MLS listings in a table with the "Select" checkbox, 
 * price, status, and so on. This is the section that appears at the bottom 
 * of ClusteredListings once the user searches for MLS clusters.
 *
 * The column definitions originally lived in ClusteredListings.
 */
const ListingsDataTable = ({
  transformedListings,
  customClusterListings,
  handleListingSelect,
  getClusterColor,
  formatNumberWithK,
  formatNumberInt,
  handleSort,
  sortField,
  sortDirection,
  useSmartClusters
}) => {
  // Add state for pagination
  const [currentPage, setCurrentPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(10);

  // Add these console logs
  console.log('Transformed Listings:', transformedListings);
  console.log('useSmartClusters:', useSmartClusters);
  console.log('Sample Listings (first 5):', transformedListings.slice(0, 5).map(listing => ({
    address: listing.formatted_address,
    status: listing.status,
    listing_type: listing.listing_type,
    cluster: listing.cluster
  })));

  // Re-display the columns exactly as they were
  const columns = [
    {
      name: 'Cluster',
      selector: row => row.cluster,
      sortable: true,
      width: '80px',
      id: 'cluster',
      sortFunction: (rowA, rowB) => {
        const a = parseInt(rowA.cluster, 10) || 0;
        const b = parseInt(rowB.cluster, 10) || 0;
        return a - b;
      },
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: getClusterColor(row.cluster),
              marginRight: '10px'
            }}
          />
          {row.cluster}
        </div>
      )
    },
    {
      name: 'Listing Type',
      selector: row => row.listing_type,
      sortable: true,
      width: '110px',
      cell: row => {
        const listingType = row.listing_type === 'MLS' ? 'MLS' : 'OFF MARKET';
        return (
          <div style={{ 
            padding: '5px 10px',
            borderRadius: '4px',
            backgroundColor: row.listing_type === 'MLS' ? '#e3f2fd' : '#f5f5f5',
            color: row.listing_type === 'MLS' ? '#1976d2' : '#424242',
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: '500'
          }}>
            {listingType}
          </div>
        );
      }
    },
    {
      name: 'Address',
      selector: row => row.address || row.formatted_address,
      sortable: true,
      width: '200px',
      cell: row => {
        const address = row.address || row.formatted_address;
        if (!address) return '';
        
        const parts = address.split(',');
        // Capitalize first letter of each word in street address, but keep the rest lowercase
        const streetAddress = parts[0].toLowerCase().replace(/\b\w/g, letter => letter.toUpperCase());
        
        // Format city, state, and zip
        const locationParts = parts.slice(1).join('').trim().split(' ');
        // Everything except the last two elements (state and zip) should be title case
        const cityParts = locationParts.slice(0, -2).join(' ').toLowerCase().replace(/\b\w/g, letter => letter.toUpperCase());
        const stateZip = locationParts.slice(-2).join(' ').toUpperCase();
        
        return (
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            textAlign: 'left'
          }}>
            <div style={{ fontSize: '0.95rem' }}>{streetAddress}</div>
            <div style={{ fontSize: '0.95rem' }}>{`${cityParts.trim()}, ${stateZip}`}</div>
          </div>
        );
      }
    },
    {
      name: 'Price',
      selector: row => row.price,
      sortable: true,
      width: '110px',
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{row.price ? `$${formatNumberWithK(row.price)}` : ''}</div>
          {row.price && (row.square_footage || row.bldg_sq_ft) && (
            <div style={{ 
              fontSize: '0.9em', 
              color: '#666',
              marginTop: '4px' 
            }}>
              ({`$${Math.round(row.price / (row.square_footage || row.bldg_sq_ft))}/sq ft`})
            </div>
          )}
        </div>
      )
    },
    {
      name: 'Year Built',
      selector: row => row.year_built,
      sortable: true,
      width: '100px',
      cell: row => row.year_built || ''
    },
    {
      name: 'Property Type',
      selector: row => row.property_type,
      sortable: true,
      width: '120px',
      cell: row => {
        const propertyType = row.property_type || '';
        return propertyType.charAt(0).toUpperCase() + propertyType.slice(1).toLowerCase();
      }
    },
    {
      name: 'Features',
      selector: row => row.features,
      sortable: false,
      width: '300px',
      wrap: true,
      cell: row => (
        <div style={{ padding: '8px 0' }}>
          {(row.bedrooms || row.bathrooms) && (
            <div>
              {row.bedrooms && <span style={{ marginRight: '8px' }}>
                <strong>Bedrooms:</strong> {row.bedrooms}
              </span>}
              {row.bedrooms && row.bathrooms && '|'}
              {row.bathrooms && <span style={{ marginLeft: '8px' }}>
                <strong>Bathrooms:</strong> {row.bathrooms}
              </span>}
            </div>
          )}
          {!useSmartClusters && (
            <div>
              {(row.garageParkingCapacity || row.garageParkingCapacity === 0) && (
                <span style={{ marginRight: '8px' }}>
                  <strong>Garage:</strong> {`${row.garageParkingCapacity} car${row.garageParkingCapacity !== 1 ? 's' : ''}`}
                </span>
              )}
              {(row.garageParkingCapacity || row.garageParkingCapacity === 0) && row.sewer && '|'}
              {row.sewer && <span style={{ marginLeft: '8px' }}>
                <strong>Sewer:</strong> {row.sewer}
              </span>}
            </div>
          )}
        </div>
      )
    },
    {
      name: 'Size',
      selector: row => row.square_footage,
      sortable: false,
      width: '180px',
      wrap: true,
      cell: row => (
        <div style={{ padding: '8px 0' }}>
          {row.square_footage && (
            <div style={{ marginBottom: '4px' }}>
              <strong>Home:</strong> {`${formatNumberInt(row.square_footage)} sq ft`}
            </div>
          )}
          {row.lot_size_sq_ft && (
            <div>
              <strong>Lot:</strong> {`${formatNumberInt(row.lot_size_sq_ft)} sq ft`}
            </div>
          )}
        </div>
      )
    },
    {
      name: 'Community',
      selector: row => row.assumed_community_name,
      sortable: true,
      width: '150px',
      cell: row => row.assumed_community_name || ''
    },
    {
      name: 'Builder',
      selector: row => row.assumed_builder_name,
      sortable: true,
      width: '150px',
      cell: row => row.assumed_builder_name || ''
    },
    {
      name: 'Latest Event',
      selector: row => row.latest_price_date || row.sale_date || row.removed_date,
      sortable: true,
      width: '180px',
      wrap: true,
      cell: row => {
        // First check if we have the standard latest event data
        // Make sure latest_price_date is not "Active" or other status values
        const hasValidLatestPriceDate = row.latest_price_date && 
                                       !['active', 'for sale', 'pending', 'sold', 'inactive'].includes(
                                         row.latest_price_date.toLowerCase()
                                       );
        
        if (hasValidLatestPriceDate || row.latest_price || row.latest_price_event) {
          return (
            <div style={{ padding: '8px 0' }}>
              {hasValidLatestPriceDate && (
                <div style={{ marginBottom: '4px' }}>
                  <strong>Date:</strong> {row.latest_price_date}
                </div>
              )}
              {row.latest_price && (
                <div style={{ marginBottom: '4px' }}>
                  <strong>Price:</strong> {`$${formatNumberWithK(row.latest_price)}`}
                </div>
              )}
              {row.latest_price_event && (
                <div>
                  <strong>Event:</strong> {row.latest_price_event}
                </div>
              )}
            </div>
          );
        }
        
        // If no standard latest event data, check for sale_date or removed_date
        if (row.sale_date || row.removed_date) {
          // Determine which date to use and set the appropriate event text
          let date;
          let event;
          
          if (row.sale_date) {
            date = row.sale_date;
            event = 'Sold';
          } else {
            date = row.removed_date;
            event = 'Sold*';  // Add asterisk for removed_date
          }
          
          const price = row.price;
          
          return (
            <div style={{ padding: '8px 0' }}>
              {date && (
                <div style={{ marginBottom: '4px' }}>
                  <strong>Date:</strong> {date}
                </div>
              )}
              {price && (
                <div style={{ marginBottom: '4px' }}>
                  <strong>Price:</strong> {`$${formatNumberWithK(price)}`}
                </div>
              )}
              <div>
                <strong>Event:</strong> {event}
              </div>
            </div>
          );
        }
        
        // If no data at all
        return '';
      }
    },
    {
      name: 'Days On Mkt',
      selector: row => row.days_on_market,
      sortable: true,
      width: '120px',
      cell: row => row.days_on_market || ''
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      width: '120px',
      cell: row => {
        // Determine status category and styling
        let backgroundColor;
        let textColor = 'white';
        
        // Normalize status for comparison
        const status = (row.status || '').toLowerCase();
        
        if (status.includes('for sale') || status.includes('active')) {
          backgroundColor = '#28a745'; // Green for active/for sale
        } else if (status.includes('pending')) {
          backgroundColor = '#ffc107'; // Yellow for pending
          textColor = '#212529'; // Darker text for better contrast on yellow
        } else {
          backgroundColor = '#dc3545'; // Red for sold, inactive, etc.
        }
        
        return (
          <div style={{
            padding: '5px 10px',
            borderRadius: '4px',
            backgroundColor: backgroundColor,
            color: textColor,
            textAlign: 'center'
          }}>
            {row.status || 'Inactive'}
          </div>
        );
      }
    },
    {
      name: 'Zillow Link',
      cell: row => (
        <a
          href={formatZillowURL(row.address)}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <button style={{ 
            padding: '5px 10px', 
            background: '#fd9245', 
            color: 'white', 
            border: 'none', 
            borderRadius: '5px'
          }}>
            View on Zillow
          </button>
        </a>
      ),
      width: '160px',
    },
    {
      name: 'Schools',
      selector: row => row.schools,
      sortable: false,
      width: '400px',
      wrap: true,
      cell: row => {
        if (!row.schools) return '';
        try {
          const schools = typeof row.schools === 'string' ? JSON.parse(row.schools) : row.schools;
          if (!Array.isArray(schools) || schools.length === 0) return '';

          return (
            <div style={{ padding: '8px 0' }}>
              {schools.map((school, index) => (
                <div key={index} style={{ marginBottom: index < schools.length - 1 ? '8px' : '0' }}>
                  <strong>{school.level} School:</strong> {school.name}<br />
                  <span style={{ 
                    color: '#666', 
                    fontSize: '0.9em' 
                  }}>
                    {school.rating && `Rating: ${school.rating}`}
                    {school.rating && school.distance && ' | '}
                    {school.distance && `Distance: ${school.distance} mi`}
                  </span>
                </div>
              ))}
            </div>
          );
        } catch (e) {
          console.error('Error processing schools data:', e);
          return '';
        }
      },
    }
  ];

  // The formatZillowURL function used in columns
  const formatZillowURL = (address) => {
    const formattedAddress = address
      .replace(/\s+/g, '-')
      .replace(/,/g, '')
      .replace(/--+/g, '-');

    return `https://www.zillow.com/homes/${formattedAddress}`;
  };

  // Custom sort function to handle different data types
  const customSort = (rows, field, direction) => {
    return [...rows].sort((a, b) => {
      // Handle different data types appropriately
      if (field === 'cluster') {
        const aVal = parseInt(a.cluster, 10) || 0;
        const bVal = parseInt(b.cluster, 10) || 0;
        return direction === 'asc' ? aVal - bVal : bVal - aVal;
      }
      
      // Handle numeric fields
      if (['price', 'year_built', 'square_footage', 'lot_size_sq_ft', 'days_on_market', 'latest_price'].includes(field)) {
        const aVal = parseFloat(a[field]) || 0;
        const bVal = parseFloat(b[field]) || 0;
        return direction === 'asc' ? aVal - bVal : bVal - aVal;
      }
      
      // Handle date fields
      if (field === 'latest_price_date') {
        const aDate = a[field] ? new Date(a[field]) : new Date(0);
        const bDate = b[field] ? new Date(b[field]) : new Date(0);
        return direction === 'asc' ? aDate - bDate : bDate - aDate;
      }
      
      // Default string comparison for other fields
      const aVal = (a[field] || '').toString().toLowerCase();
      const bVal = (b[field] || '').toString().toLowerCase();
      
      return direction === 'asc' 
        ? aVal.localeCompare(bVal)
        : bVal.localeCompare(aVal);
    });
  };

  // Handle sort changes
  const handleSortChange = (column, sortDirection) => {
    console.log('Sorting by:', column.id || column.selector.name, 'Direction:', sortDirection);
    
    // If external sort handler is provided, call it
    if (handleSort) {
      handleSort(column.id || column.selector.name, sortDirection);
    }
  };

  return (
    <DataTable
      title="Listings Data"
      columns={columns}
      data={transformedListings}
      pagination
      paginationTop
      paginationBottom={false}
      paginationPerPage={perPage}
      paginationDefaultPage={currentPage}
      onChangeRowsPerPage={(newPerPage, newPage) => {
        setPerPage(newPerPage);
        setCurrentPage(newPage);
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'auto'  // Makes it instant instead of smooth
        });
      }}
      onChangePage={(page) => {
        setCurrentPage(page);
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'auto'  // Makes it instant instead of smooth
        });
      }}
      paginationComponentOptions={{
        rowsPerPageText: 'Rows per page:',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All'
      }}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
      noDataComponent="No listings for this filter"
      responsive
      customStyles={{
        table: {
          style: {
            fontSize: '0.95rem',
          },
        },
        rows: {
          style: {
            fontSize: '0.95rem',
            borderBottom: '1px solid #e0e0e0',
            minHeight: '50px',
            margin: 0,
          },
        },
        headCells: {
          style: {
            fontSize: '1rem',
            fontWeight: 'normal',
            borderBottom: '2px solid #e0e0e0',
            padding: '2px',
            margin: 0,
          },
        },
        cells: {
          style: {
            padding: '2px',
            margin: 0,
          },
        },
        pagination: {
          style: {
            fontSize: '0.95rem',
            minHeight: '56px',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: '#e0e0e0',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            margin: '0 20px'
          },
        },
      }}
      sortServer={false}
      defaultSortFieldId="cluster"
      defaultSortAsc={true}
      onSort={handleSortChange}
      sortFunction={customSort}
      fixedHeader
      fixedHeaderScrollHeight="1300px"
    />
  );
};

export default ListingsDataTable; 