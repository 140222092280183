import React, { useRef, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import RegularClusterCharts from './RegularClusterCharts';
import Chart from 'chart.js/auto';
import { CircularProgress } from '@mui/material';

/**
 * ClusterSummary
 *
 * Displays the summary table for multiple clusters (standard scenario)
 * OR displays the custom cluster summary/charts when isCustomSummary is true.
 *
 * This houses the logic for toggling between on-market chart visuals vs.
 * off-market visuals for custom clusters that contain off-market data.
 */

function SmartClusterCharts({ data }) {
  console.log('SmartClusterCharts data:', {
    hasData: Boolean(data),
    charts: {
      price_dist: Boolean(data?.price_distribution_chart),
      monthly: Boolean(data?.monthly_sales_chart),
      sqft: Boolean(data?.price_to_sq_ft_scatter),
      days: Boolean(data?.price_to_days_scatter)
    }
  });

  const priceCanvasRef = useRef(null);
  const monthlySalesCanvasRef = useRef(null);
  const priceToSqFtCanvasRef = useRef(null);
  const priceToDaysCanvasRef = useRef(null);

  useEffect(() => {
    // Price Distribution Chart
    if (data.price_distribution_chart && priceCanvasRef.current) {
      if (priceCanvasRef.current.chartInstance) {
        priceCanvasRef.current.chartInstance.destroy();
      }
      try {
        const newChart = new Chart(priceCanvasRef.current, {
          ...data.price_distribution_chart,
          options: {
            ...data.price_distribution_chart.options,
            responsive: true,
            maintainAspectRatio: false
          }
        });
        priceCanvasRef.current.chartInstance = newChart;
      } catch (error) {
        console.error('Error initializing price chart:', error);
      }
    }

    // Monthly Sales Chart
    if (data.monthly_sales_chart && monthlySalesCanvasRef.current) {
      if (monthlySalesCanvasRef.current.chartInstance) {
        monthlySalesCanvasRef.current.chartInstance.destroy();
      }
      try {
        const newChart = new Chart(monthlySalesCanvasRef.current, {
          ...data.monthly_sales_chart,
          options: {
            ...data.monthly_sales_chart.options,
            responsive: true,
            maintainAspectRatio: false
          }
        });
        monthlySalesCanvasRef.current.chartInstance = newChart;
      } catch (error) {
        console.error('Error initializing sales chart:', error);
      }
    }

    // Price to Square Footage Chart
    if (data.price_to_sq_ft_scatter && priceToSqFtCanvasRef.current) {
      if (priceToSqFtCanvasRef.current.chartInstance) {
        priceToSqFtCanvasRef.current.chartInstance.destroy();
      }
      try {
        const newChart = new Chart(priceToSqFtCanvasRef.current, {
          ...data.price_to_sq_ft_scatter,
          options: {
            ...data.price_to_sq_ft_scatter.options,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              ...data.price_to_sq_ft_scatter.options?.plugins,
              tooltip: {
                callbacks: {
                  label: function(context) {
                    const point = context.raw;
                    const pricePerSqFt = point.y / point.x;
                    return [
                      `${context.dataset.label}`,
                      `Price: $${point.y.toLocaleString()}`,
                      `Sqft: ${point.x.toLocaleString()}`,
                      `Price/Sqft: $${pricePerSqFt.toFixed(2)}`
                    ];
                  }
                }
              }
            }
          }
        });
        priceToSqFtCanvasRef.current.chartInstance = newChart;
      } catch (error) {
        console.error('Error initializing price to sq ft chart:', error);
      }
    }

    // Price to Days on Market Chart
    if (data.price_to_days_scatter && priceToDaysCanvasRef.current) {
      if (priceToDaysCanvasRef.current.chartInstance) {
        priceToDaysCanvasRef.current.chartInstance.destroy();
      }
      try {
        const newChart = new Chart(priceToDaysCanvasRef.current, {
          ...data.price_to_days_scatter,
          options: {
            ...data.price_to_days_scatter.options,
            responsive: true,
            maintainAspectRatio: false
          }
        });
        priceToDaysCanvasRef.current.chartInstance = newChart;
      } catch (error) {
        console.error('Error initializing price to days chart:', error);
      }
    }
  }, [data]);

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ 
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '20px',
        backgroundColor: '#f8f9fa',
        padding: '20px'
      }}>
        {data.price_distribution_chart && (
          <div style={{
            height: '300px',
            backgroundColor: 'white',
            padding: '15px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}>
            <canvas ref={priceCanvasRef} />
          </div>
        )}

        {data.monthly_sales_chart && (
          <div style={{
            height: '300px',
            backgroundColor: 'white',
            padding: '15px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}>
            <canvas ref={monthlySalesCanvasRef} />
          </div>
        )}

        {data.price_to_sq_ft_scatter && (
          <div style={{
            height: '300px',
            backgroundColor: 'white',
            padding: '15px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}>
            <canvas ref={priceToSqFtCanvasRef} />
          </div>
        )}

        {data.price_to_days_scatter && (
          <div style={{
            height: '300px',
            backgroundColor: 'white',
            padding: '15px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}>
            <canvas ref={priceToDaysCanvasRef} />
          </div>
        )}
      </div>
    </div>
  );
}

// Helper function to get mode of a string array
const getMode = (arr) => {
  if (!arr || arr.length === 0) return 'N/A';
  
  const counts = arr.reduce((acc, val) => {
    if (val) { // Only count non-null/undefined values
      acc[val] = (acc[val] || 0) + 1;
    }
    return acc;
  }, {});
  
  const maxCount = Math.max(...Object.values(counts));
  const modes = Object.keys(counts).filter(key => counts[key] === maxCount);
  
  return modes[0] || 'N/A';
};

const ClusterSummary = ({
  clusterData,
  customClusterData,
  isCustomSummary,
  expandedRows,
  handleRowExpand,
  showListingsDetails,
  setShowListingsDetails,
  getClusterColor,
  formatNumberWithK,
  formatNumberInt,
  useSmartClusters,
  isLoading
}) => {
  console.log('ClusterSummary Props:', {
    hasClusterData: Boolean(clusterData),
    hasCustomData: Boolean(customClusterData),
    isCustomSummary,
    clusterDataSample: clusterData?.cluster_analyses 
      ? Object.keys(clusterData.cluster_analyses).slice(0, 1).map(key => ({
          key,
          hasCharts: {
            price_dist: Boolean(clusterData.cluster_analyses[key].price_distribution_chart),
            monthly: Boolean(clusterData.cluster_analyses[key].monthly_sales_chart),
            sqft: Boolean(clusterData.cluster_analyses[key].price_to_sq_ft_scatter),
            days: Boolean(clusterData.cluster_analyses[key].price_to_days_scatter)
          }
        }))
      : 'No cluster analyses'
  });

  // State used for smart clusters only
  const [smartExpandedRows, setSmartExpandedRows] = React.useState({});

  // Define the standard cluster columns for clusterData (non-custom view)
  const clusterColumns = [
    {
      name: 'Cluster',
      width: '90px',
      selector: row => row.cluster,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: getClusterColor(row.cluster),
              marginRight: '10px'
            }}
          />
          {row.cluster}
        </div>
      )
    },
    {
      name: 'Count',
      width: '90px',
      selector: row => row.count,
      sortable: true,
      cell: row => formatNumberWithK(row.count)
    },
    {
      name: 'Avg\nPrice',
      selector: row => row.avgPrice,
      sortable: true,
      width: '130px',
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{`$${formatNumberWithK(row.avgPrice)}`}</div>
          <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
            {`Min: $${formatNumberWithK(row.minPrice)}`}
          </div>
          <div style={{ fontSize: '0.85em', color: '#666' }}>
            {`Max: $${formatNumberWithK(row.maxPrice)}`}
          </div>
        </div>
      )
    },
    {
      name: 'Avg\nSq Ft',
      width: '130px',
      selector: row => row.avgSquareFootage,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{formatNumberInt(row.avgSquareFootage, true)}</div>
          <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
            {`Min: ${formatNumberInt(row.minSquareFootage, true)}`}
          </div>
          <div style={{ fontSize: '0.85em', color: '#666' }}>
            {`Max: ${formatNumberInt(row.maxSquareFootage, true)}`}
          </div>
        </div>
      )
    },
    {
      name: 'Avg $\nper Sq Ft',
      width: '130px',
      selector: row => row.avgPricePerSqFt,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{`$${formatNumberInt(row.avgPricePerSqFt)}`}</div>
          <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
            {`Min: $${formatNumberInt(row.minPricePerSqFt)}`}
          </div>
          <div style={{ fontSize: '0.85em', color: '#666' }}>
            {`Max: $${formatNumberInt(row.maxPricePerSqFt)}`}
          </div>
        </div>
      )
    }
  ];

  // Define the smart cluster columns (used when smart clusters are active)
  const smartClusterColumns = [
    {
      name: 'Cluster',
      width: '90px',
      selector: row => {
        // Extract just the number for sorting
        const clusterStr = String(row.cluster);
        const clusterNum = clusterStr.includes(' ') 
          ? parseInt(clusterStr.split(' ')[1]) 
          : parseInt(clusterStr);
        return isNaN(clusterNum) ? 0 : clusterNum;
      },
      sortable: true,
      cell: row => {
        const clusterStr = String(row.cluster);
        const clusterNum = clusterStr.includes(' ') 
          ? parseInt(clusterStr.split(' ')[1]) 
          : parseInt(clusterStr);

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: getClusterColor(isNaN(clusterNum) ? 0 : clusterNum),
                marginRight: '10px'
              }}
            />
            {isNaN(clusterNum) ? clusterStr : clusterNum}
          </div>
        );
      }
    },
    {
      name: 'Count',
      width: '90px',
      selector: row => (row.listings || []).length,
      sortable: true,
      cell: row => formatNumberWithK((row.listings || []).length)
    },
    {
      name: 'Avg Price',
      selector: row => {
        const listings = row.listings || [];
        if (listings.length === 0) return 0;
        const avgPrice = listings.reduce((sum, listing) => sum + (listing.price || 0), 0) / listings.length;
        return avgPrice;
      },
      sortable: true,
      width: '130px',
      cell: row => {
        const listings = row.listings || [];
        if (listings.length === 0) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>$0</div>
              <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
                Min: $0
              </div>
              <div style={{ fontSize: '0.85em', color: '#666' }}>
                Max: $0
              </div>
            </div>
          );
        }

        const prices = listings
          .map(listing => listing.price)
          .filter(price => typeof price === 'number' && !isNaN(price));
        
        if (prices.length === 0) return '$0';

        const avgPrice = prices.reduce((sum, price) => sum + price, 0) / prices.length;
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{`$${formatNumberWithK(avgPrice)}`}</div>
            <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
              {`Min: $${formatNumberWithK(minPrice)}`}
            </div>
            <div style={{ fontSize: '0.85em', color: '#666' }}>
              {`Max: $${formatNumberWithK(maxPrice)}`}
            </div>
          </div>
        );
      }
    },
    {
      name: 'Builder',
      width: '150px',
      selector: row => {
        const listings = row.listings || [];
        const builderNames = listings.map(l => l.assumed_builder_name);
        return getMode(builderNames);
      },
      sortable: true,
      cell: row => {
        const listings = row.listings || [];
        const builderNames = listings.map(l => l.assumed_builder_name);
        return getMode(builderNames);
      }
    },
    {
      name: 'Community',
      width: '150px',
      selector: row => {
        const listings = row.listings || [];
        const communityNames = listings.map(l => l.assumed_community_name);
        return getMode(communityNames);
      },
      sortable: true,
      cell: row => {
        const listings = row.listings || [];
        const communityNames = listings.map(l => l.assumed_community_name);
        return getMode(communityNames);
      }
    }
  ];

  // Define custom cluster columns for custom analysis.
  // We are showing only Count, Avg Price, Avg Sq Ft and Avg $ per Sq Ft.
  const customClusterColumns = [
    {
      name: 'Count',
      width: '90px',
      selector: row => row.count,
      sortable: true,
      cell: row => formatNumberWithK(row.count)
    },
    {
      name: 'Avg Price',
      selector: row => row.avgPrice,
      sortable: true,
      width: '130px',
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{`$${formatNumberWithK(row.avgPrice)}`}</div>
          <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
            {`Min: $${formatNumberWithK(row.minPrice)}`}
          </div>
          <div style={{ fontSize: '0.85em', color: '#666' }}>
            {`Max: $${formatNumberWithK(row.maxPrice)}`}
          </div>
        </div>
      )
    },
    {
      name: 'Avg Sq Ft',
      width: '130px',
      selector: row => row.avgSquareFootage,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{formatNumberInt(row.avgSquareFootage, true)}</div>
          <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
            {`Min: ${formatNumberInt(row.minSquareFootage, true)}`}
          </div>
          <div style={{ fontSize: '0.85em', color: '#666' }}>
            {`Max: ${formatNumberInt(row.maxSquareFootage, true)}`}
          </div>
        </div>
      )
    },
    {
      name: 'Avg $ per Sq Ft',
      width: '130px',
      selector: row => row.avgPricePerSqFt,
      sortable: true,
      cell: row => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{`$${formatNumberInt(row.avgPricePerSqFt)}`}</div>
          <div style={{ fontSize: '0.85em', color: '#666', marginTop: '4px' }}>
            {`Min: $${formatNumberInt(row.minPricePerSqFt)}`}
          </div>
          <div style={{ fontSize: '0.85em', color: '#666' }}>
            {`Max: $${formatNumberInt(row.maxPricePerSqFt)}`}
          </div>
        </div>
      )
    }
  ];

  // ---------- Render Section ----------

  // Add loading overlay if isLoading is true
  if (isLoading) {
    return (
      <div className="cluster-summary-loading" style={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '300px',
        width: '100%',
        backgroundColor: '#f8f9fa',
        borderRadius: '8px'
      }}>
        <CircularProgress size={60} style={{ color: '#E26313' }} />
        <p style={{ marginTop: '16px', color: '#666' }}>Fetching listings data...</p>
      </div>
    );
  }

  // If using smart clusters and we have cluster data
  if (useSmartClusters && clusterData?.cluster_analyses) {
    const smartClusterRows = Object.entries(clusterData.cluster_analyses).map(([key, data]) => ({
      cluster: key,
      listings: data.listings || [],
      charts: {
        price_distribution_chart: data.price_distribution_chart,
        monthly_sales_chart: data.monthly_sales_chart,
        price_to_sq_ft_scatter: data.price_to_sq_ft_scatter,
        price_to_days_scatter: data.price_to_days_scatter
      }
    }));

    // Pre-sort the data by cluster number
    smartClusterRows.sort((a, b) => {
      const aClusterStr = String(a.cluster);
      const bClusterStr = String(b.cluster);
      
      const aClusterNum = aClusterStr.includes(' ') 
        ? parseInt(aClusterStr.split(' ')[1]) 
        : parseInt(aClusterStr);
      
      const bClusterNum = bClusterStr.includes(' ') 
        ? parseInt(bClusterStr.split(' ')[1]) 
        : parseInt(bClusterStr);
      
      return (isNaN(aClusterNum) ? 0 : aClusterNum) - (isNaN(bClusterNum) ? 0 : bClusterNum);
    });

    return (
      <div className="smart-cluster-summary">
        <DataTable
          title="Smart Cluster Analysis"
          columns={smartClusterColumns}
          data={smartClusterRows}
          sortServer={false}
          expandableRows
          expandableRowsComponent={({ data }) => (
            <SmartClusterCharts data={data.charts} />
          )}
          expandableRowExpanded={row => !!smartExpandedRows[row.cluster]}
          onRowExpandToggled={(expanded, row) => {
            setSmartExpandedRows(prev => ({
              ...prev,
              [row.cluster]: expanded
            }));
          }}
          pagination
          noDataComponent="No smart cluster data available"
          customStyles={{
            table: { style: { fontSize: '0.9rem' } },
            rows: { style: { fontSize: '0.9rem', borderBottom: '1px solid #e0e0e0', minHeight: '60px' } },
            headCells: { style: {
              fontSize: '0.9rem',
              borderBottom: '2px solid #e0e0e0',
              fontWeight: 'bold',
              padding: '8px 8px',
              whiteSpace: 'pre-line',
              wordWrap: 'break-word',
              height: 'auto',
              minHeight: '60px'
            }},
            cells: { style: { fontSize: '0.9rem', padding: '8px 8px', whiteSpace: 'normal', wordWrap: 'break-word' } }
          }}
        />
      </div>
    );
  }

  // When not in custom view, render the regular cluster summary.
  if (!isCustomSummary) {
    const dataToRender = Array.isArray(clusterData) ? clusterData : clusterData?.cluster_analyses || [];
    console.log('Data being passed to DataTable:', {
      isArray: Array.isArray(dataToRender),
      length: dataToRender.length,
      sampleData: dataToRender[0] ? {
        hasCharts: {
          price_dist: Boolean(dataToRender[0].price_distribution_chart),
          monthly: Boolean(dataToRender[0].monthly_sales_chart),
          sqft: Boolean(dataToRender[0].price_to_sq_ft_scatter),
          days: Boolean(dataToRender[0].price_to_days_scatter)
        }
      } : 'No data'
    });

    return (
      <div className="regular-cluster-summary">
        <DataTable
          title="Cluster Summary"
          columns={clusterColumns}
          data={dataToRender}
          pagination
          expandableRows
          expandableRowsComponent={({ data }) => {
            console.log('ExpandableRow data:', {
              hasData: Boolean(data),
              charts: {
                price_dist: Boolean(data?.price_distribution_chart),
                monthly: Boolean(data?.monthly_sales_chart),
                sqft: Boolean(data?.price_to_sq_ft_scatter),
                days: Boolean(data?.price_to_days_scatter)
              }
            });
            
            return (
              <RegularClusterCharts data={{
                price_distribution_chart: data.price_distribution_chart,
                monthly_sales_chart: data.monthly_sales_chart,
                price_to_sq_ft_scatter: data.price_to_sq_ft_scatter,
                price_to_days_scatter: data.price_to_days_scatter
              }} />
            );
          }}
          expandableRowExpanded={row => !!smartExpandedRows[row.cluster]}
          onRowExpandToggled={(expanded, row) => {
            setSmartExpandedRows(prev => ({
              ...prev,
              [row.cluster]: expanded
            }));
          }}
          noDataComponent="No listings for this filter"
          customStyles={{
            table: { style: { fontSize: '0.9rem' } },
            rows: { style: { fontSize: '0.9rem', borderBottom: '1px solid #e0e0e0', minHeight: '60px' } },
            headCells: { style: {
                fontSize: '0.9rem',
                borderBottom: '2px solid #e0e0e0',
                fontWeight: 'bold',
                padding: '8px 8px',
                whiteSpace: 'pre-line',
                wordWrap: 'break-word',
                height: 'auto',
                minHeight: '60px'
              }
            },
            cells: { style: { fontSize: '0.9rem', padding: '8px 8px', whiteSpace: 'normal', wordWrap: 'break-word' } }
          }}
        />
      </div>
    );
  }

  // For custom cluster analysis, render a static table (no expandable rows)
  // followed by the 4 charts underneath.
  if (isCustomSummary && customClusterData) {
    return (
      <div className="custom-cluster-summary">
        <DataTable
          title="Custom Boundary Analysis"
          columns={customClusterColumns}
          data={[customClusterData[0]]}
          noDataComponent="No custom cluster data"
          pagination={false}
          customStyles={{
            table: { style: { fontSize: '0.9rem' } },
            rows: { style: { fontSize: '0.9rem', borderBottom: '1px solid #e0e0e0', minHeight: '60px' } },
            headCells: { style: {
                fontSize: '0.9rem',
                borderBottom: '2px solid #e0e0e0',
                fontWeight: 'bold',
                padding: '8px 8px',
                whiteSpace: 'pre-line',
                wordWrap: 'break-word',
                height: 'auto',
                minHeight: '60px'
              }
            },
            cells: { style: { fontSize: '0.9rem', padding: '8px 8px', whiteSpace: 'normal', wordWrap: 'break-word' } }
          }}
        />

        <div style={{ marginTop: '20px' }}>
          <RegularClusterCharts 
            data={{
              ...customClusterData[0],
              price_distribution_chart: customClusterData[0]?.price_distribution_chart,
              monthly_sales_chart: customClusterData[0]?.monthly_sales_chart,
              price_to_sq_ft_scatter: customClusterData[0]?.price_to_sq_ft_scatter,
              price_to_days_scatter: customClusterData[0]?.price_to_days_scatter
            }}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default ClusterSummary; 