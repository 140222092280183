// src/components/ChartComponent.js
import React, { useEffect, useRef, memo } from 'react';
import Chart from 'chart.js/auto';

const ChartComponent = memo(({ chartData }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (!chartData) return;
    
    const ctx = chartRef.current.getContext('2d');
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Parse the configuration based on input type
    let chartConfig;
    if (typeof chartData === 'string') {
      chartConfig = JSON.parse(chartData);
    } else if (chartData.chart_js_json) {
      chartConfig = typeof chartData.chart_js_json === 'string' 
        ? JSON.parse(chartData.chart_js_json)
        : chartData.chart_js_json;
    } else {
      chartConfig = chartData;
    }

    // Force responsive behavior
    chartConfig.options = {
      responsive: true,
      maintainAspectRatio: true,
      ...chartConfig.options,
    };

    // Convert string callbacks to functions using a safer approach
    if (chartConfig.options?.scales?.y?.ticks?.callback && 
        typeof chartConfig.options.scales.y.ticks.callback === 'string') {
      chartConfig.options.scales.y.ticks.callback = (value) => {
        return value.toLocaleString();
      };
    }

    // Convert tooltip callback string to function
    if (chartConfig.options?.plugins?.tooltip?.callbacks?.label && 
        typeof chartConfig.options.plugins.tooltip.callbacks.label === 'string') {
      chartConfig.options.plugins.tooltip.callbacks.label = function(context) {
        return context.dataset.label + ': ' + context.parsed.y.toLocaleString();
      };
    }

    if (chartConfig.options?.scales?.y?.ticks?.stepSize && 
        typeof chartConfig.options.scales.y.ticks.stepSize === 'string') {
      // Create a safe stepSize function that returns a fixed step size
      chartConfig.options.scales.y.ticks.stepSize = (context) => {
        // You might want to add specific step size logic here
        return 1;
      };
    }

    chartInstance.current = new Chart(ctx, chartConfig);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartData]);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <canvas ref={chartRef} />
    </div>
  );
});

export default ChartComponent;
