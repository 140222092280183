import React, { useState, useEffect, useRef, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import './ClusteredListings.css';
import api from '../../api';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import ListingsDataTable from './ListingsDataTable';
import ClusterSummary from './ClusterSummary';
import ComponentDescription from '../ComponentDescription';

// Replace the hardcoded token with environment variable
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

// Replace the static clusterColors array with a function to generate colors dynamically
const generateClusterColor = (clusterId, isOffMarket = false) => {
  // Use the golden ratio to help spread the hues around the color wheel
  const goldenRatio = 0.618033988749895;
  
  // Generate a hue value between 0 and 360
  // Multiply by clusterId and use modulo to ensure we stay within 0-360
  const hue = (clusterId * goldenRatio * 360) % 360;
  
  // Use fixed saturation and lightness values for consistency
  const saturation = 70; // Percentage
  const lightness = isOffMarket ? 70 : 50;  // Changed from 80 to 65 for off-market listings
  
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
};

// Add this function before your main component
const transformClusterData = (clusters) => {
  console.log('transformClusterData input:', clusters);
  
  return clusters.map(cluster => {
    console.log('Processing cluster:', cluster);
    
    // First, extract all the chart data
    const chartData = {
      price_distribution_chart: cluster.price_distribution_chart,
      monthly_sales_chart: cluster.monthly_sales_chart,
      price_to_sq_ft_scatter: cluster.price_to_sq_ft_scatter,
      price_to_days_scatter: cluster.price_to_days_scatter
    };

    console.log('Extracted chart data:', chartData);

    // Then create the transformed data
    const transformedData = {
      // Basic metrics
      cluster: cluster.Cluster || cluster.cluster,
      count: cluster.Count || cluster.count,
      avgPrice: cluster['Avg Price'],
      minPrice: cluster['Min Price'],
      maxPrice: cluster['Max Price'],
      avgSquareFootage: cluster['Avg Square Footage'],
      minSquareFootage: cluster['Min Square Footage'],
      maxSquareFootage: cluster['Max Square Footage'],
      avgPricePerSqFt: cluster['Avg Price per Sq Ft'],
      minPricePerSqFt: cluster['Min Price per Sq Ft'],
      maxPricePerSqFt: cluster['Max Price per Sq Ft'],
      priceStdDev: cluster['Price Std Dev'],
      squareFootageStdDev: cluster['Square Footage Std Dev'],
      
      // Chart data - spread at the end to ensure it's not overwritten
      ...chartData
    };

    console.log('Final transformed data:', transformedData);
    return transformedData;
  });
};

const ClusteredListings = ({ 
  selectedLocation, 
  selectedDistance, 
  selectedClient,
  locationInfo,
  listingsFilters
}) => {
  // State Declarations
  const [selectedCluster, setSelectedCluster] = useState(15); // Set default to 15 clusters
  const [clusterData, setClusterData] = useState([]);
  const [transformedListings, setTransformedListings] = useState([]);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [isUpdatingMarkers, setIsUpdatingMarkers] = useState(false);
  const [showInactive, setShowInactive] = useState(true);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [customClusterListings, setCustomClusterListings] = useState(new Set());
  const [customClusterData, setCustomClusterData] = useState(null);
  const [sortField, setSortField] = useState('cluster');
  const [sortDirection, setSortDirection] = useState('asc');
  const [isCustomSummary, setIsCustomSummary] = useState(false);
  const [showListingsDetails, setShowListingsDetails] = useState(false);
  const [useSmartClusters, setUseSmartClusters] = useState(false);
  const [showOffMarket, setShowOffMarket] = useState(true);
  const [isOffMarketButtonEnabled, setIsOffMarketButtonEnabled] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  // Refs
  const popupRef = useRef(null);
  const mapRef = useRef(null);
  const communityMarkerRef = useRef(null);
  const drawRef = useRef(null);
  const mapMarkersRef = useRef([]);
  const showOffMarketRef = useRef(true);

  // Add useEffect to initialize the popup
  useEffect(() => {
    popupRef.current = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });
    return () => {
      if (popupRef.current) {
        popupRef.current.remove();
      }
    };
  }, []);

  // Update the getClusterColor function
  const getClusterColor = useCallback((cluster, status) => {
    if (!cluster) return 'gray';
    return generateClusterColor(cluster);
  }, []);

  // Clear map markers
  const clearMapMarkers = useCallback(() => {
    // Remove all markers from the map and empty our ref
    mapMarkersRef.current.forEach(marker => {
      if (typeof marker.remove === 'function') {
        marker.remove();
      }
    });
    mapMarkersRef.current = [];
  }, []);

  // Transform listings data
  const transformListingsData = useCallback((listings) => {
    const transformed = listings
      .map(listing => {
        if (!listing.latitude || !listing.longitude) return null;
        
        let schools = listing.schools;
        try {
          if (typeof listing.schools === 'string') {
            schools = JSON.parse(listing.schools);
          }
        } catch (e) {
          console.error('Error parsing schools in transform:', e);
          schools = null;
        }

        return {
          listingId: listing.id || '',
          latitude: listing.latitude,
          longitude: listing.longitude,
          listing_type: listing.listing_type || '',
          address: listing.formatted_address || listing.address || '',
          bedrooms: listing.bedrooms || listing.beds || '',
          bathrooms: listing.bathrooms || listing.bath || '',
          cluster: listing.cluster ? String(listing.cluster) : '',
          price: listing.price || '',
          property_type: listing.property_type || '',
          year_built: listing.year_built || '',
          lot_size_sq_ft: listing.lot_size_sq_ft || '',
          days_on_market: listing.days_on_market || '',
          status: listing.status || '',
          garageParkingCapacity: listing.garageParkingCapacity,
          sewer: listing.sewer,
          schools: schools,
          square_footage: listing.square_footage || listing.bldg_sq_ft || '',
          latest_price_date: listing.latest_price_date || '',
          latest_price_event: listing.latest_price_event || '',
          latest_price: listing.latest_price || '',
          assumed_community_name: listing.assumed_community_name || '',
          assumed_builder_name: listing.assumed_builder_name || '',
          sale_date: listing.sale_date || '',
          removed_date: listing.removed_date || ''
        };
      })
      .filter(Boolean);
    setTransformedListings(transformed);
    return transformed;
  }, []);

  // Create marker
  const createMarker = useCallback((listing, mapInstance) => {
    // Add debug logging for your specific address
    if (listing.address && listing.address.includes('L15 Ocala Ct,')) {
      console.log('Found target address:', {
        address: listing.address,
        latitude: listing.latitude,
        longitude: listing.longitude,
        hasCoordinates: Boolean(listing.longitude && listing.latitude)
      });
    }

    if (!listing.longitude || !listing.latitude) {
      // Add debug logging for missing coordinates
      if (listing.address && listing.address.includes('L15 Ocala Ct')) {
        console.warn('Target address missing coordinates:', listing);
      }
      return null;
    }

    const el = document.createElement('div');
    el.className = 'marker';
    
    // Check if listing is off market
    const isOffMarket = listing.status === 'Off Market' || listing.listing_type === 'Off Market';
    
    el.style.backgroundColor = generateClusterColor(listing.cluster, isOffMarket);
    el.style.width = '20px';
    el.style.height = '20px';
    el.style.borderRadius = '50%';
    el.style.border = '2px solid white';
    el.dataset.status = listing.status || '';
    el.dataset.cluster = listing.cluster;
    el.dataset.isOffMarket = isOffMarket;
    
    // Add display:none if off market and showOffMarket is false
    if (isOffMarket && !showOffMarketRef.current) {
      el.style.display = 'none';
    }

    const formattedPrice = typeof listing.price === 'number' 
      ? listing.price.toLocaleString('en-US', { 
          style: 'currency', 
          currency: 'USD',
          maximumFractionDigits: 0
        })
      : 'N/A';

    // Only show beds/baths section if either value is available and not 'N/A'
    const bedsAndBaths = (listing.bedrooms && listing.bedrooms !== 'N/A') || (listing.bathrooms && listing.bathrooms !== 'N/A') 
      ? `<div style="margin-bottom: 4px;">
           ${listing.bedrooms && listing.bedrooms !== 'N/A' ? `<strong>Beds:</strong> ${listing.bedrooms}` : ''}
           ${listing.bedrooms && listing.bedrooms !== 'N/A' && listing.bathrooms && listing.bathrooms !== 'N/A' ? ' | ' : ''}
           ${listing.bathrooms && listing.bathrooms !== 'N/A' ? `<strong>Baths:</strong> ${listing.bathrooms}` : ''}
         </div>`
      : '';
      
    // Only show fields if they have values
    const saleDate = listing.sale_date || listing.removed_date;
    const saleDateSection = saleDate 
      ? `<div style="margin-bottom: 4px;"><strong>Sale Date:</strong> ${saleDate}</div>` 
      : '';
      
    const squareFootageSection = listing.square_footage 
      ? `<div style="margin-bottom: 4px;"><strong>Square Footage:</strong> ${listing.square_footage} Sq Ft</div>` 
      : '';
      
    const communityNameSection = listing.assumed_community_name 
      ? `<div style="margin-bottom: 4px;"><strong>Community Name:</strong> ${listing.assumed_community_name}</div>` 
      : '';
      
    const builderNameSection = listing.assumed_builder_name 
      ? `<div style="margin-bottom: 4px;"><strong>Builder Name:</strong> ${listing.assumed_builder_name}</div>` 
      : '';

    const popupContent = `
      <div style="padding: 8px;">
        <div style="color: #666; font-size: 1.1em; margin-bottom: 4px;">
          ${(listing.listing_type || 'N/A')} Listing
        </div>
        <div style="font-weight: bold; margin-bottom: 4px;">
          Cluster ${listing.cluster || 'N/A'}
        </div>
        
        <div style="margin-bottom: 4px;">
          <strong>Address:</strong> ${listing.address !== 'N/A' ? listing.address : 'Address not available'}
        </div>
        <div style="margin-bottom: 4px;">
          <strong>Price:</strong> ${formattedPrice}
        </div>
        ${bedsAndBaths}
        ${squareFootageSection}
        ${saleDateSection}
        ${communityNameSection}
        ${builderNameSection}
      </div>
    `;

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      className: 'marker-popup'
    }).setHTML(popupContent);

    const marker = new mapboxgl.Marker(el)
      .setLngLat([listing.longitude, listing.latitude])
      .setPopup(popup);

    if (mapInstance.loaded()) {
      marker.addTo(mapInstance);
    }

    el.addEventListener('mouseenter', () => popup.addTo(mapInstance));
    el.addEventListener('mouseleave', () => popup.remove());

    return marker;
  }, [showOffMarketRef]);

  // Add markers to map
  const addMarkersToMap = useCallback(async (listingsData, mapInstance) => {
    console.log('addMarkersToMap called with:', {
      listingsCount: listingsData?.length,
      hasMapInstance: Boolean(mapInstance),
      isMapLoaded: mapInstance?.loaded(),
      isUpdatingMarkers,
      hasSelectedCoordinates: Boolean(selectedCoordinates),
      coordinates: selectedCoordinates
    });

    if (!mapInstance || !mapInstance.loaded() || isUpdatingMarkers) {
      console.log('Map not ready or markers already updating');
      return;
    }

    try {
      setIsUpdatingMarkers(true);
      clearMapMarkers();
      
      // Enable button and set showOffMarket to true after search
      setIsOffMarketButtonEnabled(true);
      setShowOffMarket(true);

      const chunkSize = 50;
      const chunks = [];
      for (let i = 0; i < listingsData.length; i += chunkSize) {
        chunks.push(listingsData.slice(i, i + chunkSize));
      }

      let processedMarkers = [];
      let currentChunk = 0;

      const processNextChunk = () => {
        if (currentChunk >= chunks.length) {
          mapMarkersRef.current = processedMarkers;
          console.log('Processing complete:', {
            totalMarkersCreated: processedMarkers.length,
            hasProcessedMarkers: processedMarkers.length > 0,
            communityCoordinates: selectedCoordinates
          });

          if (processedMarkers.length > 0) {
            const bounds = new mapboxgl.LngLatBounds();
            processedMarkers.forEach(marker => {
              const lngLat = marker.getLngLat();
              bounds.extend(lngLat);
            });
            if (selectedCoordinates) {
              const popupContent = `
                <div style="padding: 8px;">
                  <div style="font-weight: bold; margin-bottom: 4px;">
                    Your Community
                  </div>
                </div>
              `;
              
              const popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false,
                offset: [0, 15],
                anchor: 'top',
              }).setHTML(popupContent);

              communityMarkerRef.current = new mapboxgl.Marker({
                color: '#E26313'
              })
                .setLngLat([selectedCoordinates.longitude, selectedCoordinates.latitude])
                .setPopup(popup)
                .addTo(mapInstance);

              communityMarkerRef.current.getElement().addEventListener('mouseenter', () => popup.addTo(mapInstance));
              communityMarkerRef.current.getElement().addEventListener('mouseleave', () => popup.remove());

              bounds.extend([selectedCoordinates.longitude, selectedCoordinates.latitude]);
            }
            
            setTimeout(() => {
              try {
                mapInstance.fitBounds(bounds, {
                  padding: { top: 50, bottom: 50, left: 50, right: 50 },
                  duration: 1000,
                  maxZoom: 15
                });
                console.log('Successfully fit bounds to map');
              } catch (error) {
                console.error('Error fitting bounds:', error);
              }
            }, 100);
          } else {
            console.log('No markers to fit bounds to');
          }
          setIsUpdatingMarkers(false);
          return;
        }

        const chunk = chunks[currentChunk];
        const newMarkers = chunk
          .map(listing => createMarker(listing, mapInstance))
          .filter(Boolean);

        processedMarkers = [...processedMarkers, ...newMarkers];
        currentChunk++;
        requestAnimationFrame(processNextChunk);
      };

      processNextChunk();
    } catch (err) {
      console.error('Error in addMarkersToMap:', err);
      setIsUpdatingMarkers(false);
    }
  }, [
    clearMapMarkers,
    createMarker,
    isUpdatingMarkers,
    selectedCoordinates
  ]);

  // Fetch location data on selectedLocation change
  useEffect(() => {
    const fetchLocationData = async () => {
      if (!selectedLocation) return;
      try {
        const coordinatesResponse = await api.get('/api/get-location-details', {
          params: { locationId: selectedLocation },
        });
        setSelectedCoordinates(coordinatesResponse.data);
      } catch (error) {
        console.error('Error fetching location data:', error);
        setError('Failed to fetch location data.');
      }
    };
    fetchLocationData();
  }, [selectedLocation]);

  // Initialize map
  const initializeMap = useCallback(() => {
    console.log('Initializing Mapbox map...');
    const mapContainer = document.getElementById('map');
    if (!mapContainer) {
      console.error('Map container not found');
      return;
    }

    try {
      if (mapRef.current && !mapRef.current.removed) {
        mapRef.current.remove();
      }
      mapContainer.innerHTML = '';

      const mapInstance = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/satellite-streets-v12',
        center: [selectedCoordinates.longitude, selectedCoordinates.latitude],
        zoom: 14,
        antialias: true,
      });

      mapInstance.on('load', () => {
        if (!mapInstance.removed && selectedCoordinates) {
          const popupContent = `
            <div style="padding: 8px;">
              <div style="font-weight: bold; margin-bottom: 4px;">
                Your Community
              </div>
            </div>
          `;
          
          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
            offset: [0, 15],
            anchor: 'top',
          }).setHTML(popupContent);

          communityMarkerRef.current = new mapboxgl.Marker({
            color: '#E26313'
          })
            .setLngLat([selectedCoordinates.longitude, selectedCoordinates.latitude])
            .setPopup(popup)
            .addTo(mapInstance);

          communityMarkerRef.current.getElement().addEventListener('mouseenter', () => popup.addTo(mapInstance));
          communityMarkerRef.current.getElement().addEventListener('mouseleave', () => popup.remove());
        }
        
        if (!mapInstance.removed && transformedListings.length > 0) {
          addMarkersToMap(transformedListings, mapInstance);
        }
      });

      mapInstance.on('error', (e) => {
        console.error('Mapbox GL JS Error:', e.error);
      });

      mapRef.current = mapInstance;
    } catch (error) {
      console.error('Error initializing map:', error);
    }
  }, [selectedCoordinates, transformedListings, addMarkersToMap]);

  // When selectedCoordinates changes
  useEffect(() => {
    console.log('selectedCoordinates useEffect triggered:', {
      hasSelectedCoordinates: Boolean(selectedCoordinates),
      coordinates: selectedCoordinates,
      hasMapRef: Boolean(mapRef.current)
    });

    if (selectedCoordinates && !mapRef.current) {
      console.log('Initializing map because coordinates exist but map does not');
      initializeMap();
    } else if (selectedCoordinates && mapRef.current) {
      if (communityMarkerRef.current) {
        communityMarkerRef.current.remove();
      }

      const popupContent = `
        <div style="padding: 8px;">
          <div style="font-weight: bold; margin-bottom: 4px;">
            Your Community
          </div>
        </div>
      `;
      
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: [0, 15],
        anchor: 'top',
      }).setHTML(popupContent);

      communityMarkerRef.current = new mapboxgl.Marker({
        color: '#E26313'
      })
        .setLngLat([selectedCoordinates.longitude, selectedCoordinates.latitude])
        .setPopup(popup)
        .addTo(mapRef.current);

      communityMarkerRef.current.getElement().addEventListener('mouseenter', () => popup.addTo(mapRef.current));
      communityMarkerRef.current.getElement().addEventListener('mouseleave', () => popup.remove());
    }
  }, [selectedCoordinates, initializeMap]);

  // Helper functions to format numeric data
  const formatNumberWithK = (num) => {
    if (!num && num !== 0) return 'N/A';
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`;
    }
    if (num >= 1000) {
      return `${Math.round(num / 1000)}k`;
    }
    return num.toLocaleString();
  };

  const formatNumberInt = (num, isSquareFootage = false) => {
    if (!num && num !== 0) return 'N/A';
    if (isSquareFootage) {
      return Math.round(num).toLocaleString();
    }
    if (num < 1000) {
      return num.toFixed(2);
    }
    return Math.round(num).toLocaleString();
  };

  // Expand row
  const handleRowExpand = (row) => {
    setExpandedRows(prev => ({
      ...prev,
      [row.cluster]: !prev[row.cluster]
    }));
  };

  // getCustomClusterData
  const getCustomClusterData = useCallback(async (selectedListings) => {
    try {
      return {
        onMarketListings: selectedListings.onMarket,
        offMarketListings: selectedListings.offMarket,
      };
    } catch (error) {
      console.log('Error details: ', error);
      return null;
    }
  }, []);

  // handleDrawCreate
  const handleDrawCreate = useCallback(({ features }) => {
    console.log('handleDrawCreate called with features:', features);
    
    if (features && features[0] && features[0].geometry) {
      const coordinates = features[0].geometry.coordinates[0];
      
      const params = {
        locationId: selectedLocation,
        distance: selectedDistance,
        clusters: 1
      };
      
      api.post('/api/get-cluster-data', {
        boundary: coordinates,
        ...params
      }, {
        params
      })
      .then(response => {
        console.log('Raw API response for custom cluster:', {
          fullResponse: response.data,
          clusterSummary: response.data.cluster_summary,
          firstCluster: response.data.cluster_summary?.[0],
          charts: response.data.cluster_summary?.[0] ? {
            price_dist: Boolean(response.data.cluster_summary[0].price_distribution_chart),
            monthly: Boolean(response.data.cluster_summary[0].monthly_sales_chart),
            sqft: Boolean(response.data.cluster_summary[0].price_to_sq_ft_scatter),
            days: Boolean(response.data.cluster_summary[0].price_to_days_scatter)
          } : null
        });

        if (response.data.cluster_summary && response.data.cluster_summary.length > 0) {
          const transformedData = transformClusterData(response.data.cluster_summary);
          console.log('Transformed custom cluster data:', transformedData);
          setCustomClusterData(transformedData);
          setIsCustomSummary(true);
        }
      })
      .catch(error => {
        console.error('Error fetching cluster data:', error);
      });
    }
  }, [selectedLocation, selectedDistance]);

  // handleDrawDelete
  const handleDrawDelete = useCallback(() => {
    // Reset to normal view without boundary
    const params = new URLSearchParams({
      locationId: selectedLocation,
      distance: selectedDistance,
      clusters: selectedCluster
    });
    
    // Make regular GET request without boundary
    fetch(`/api/get-cluster-data?${params}`)
      .then(response => response.json())
      .then(data => {
        setClusterData(data);
      })
      .catch(error => {
        console.error('Error fetching cluster data:', error);
      });
  }, [selectedLocation, selectedDistance, selectedCluster, setClusterData]);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      console.log('Component cleanup triggered');
      if (mapRef.current) {
        console.log('Cleaning up map');
        try {
          const map = mapRef.current;
          if (!map.removed) {
            map.remove();
          }
        } catch (error) {
          console.warn('Error removing map:', error);
        }
        mapRef.current = null;
      }

      if (communityMarkerRef.current) {
        console.log('Cleaning up community marker');
        try {
          communityMarkerRef.current.remove();
        } catch (error) {
          console.warn('Error removing community marker:', error);
        }
        communityMarkerRef.current = null;
      }
    };
  }, []);

  // Separate effect for drawRef cleanup
  useEffect(() => {
    return () => {
      if (drawRef.current && mapRef.current) {
        try {
          const map = mapRef.current;
          if (map && !map.removed) {
            if (map.listens && map.listens('draw.create')) {
              map.off('draw.create', handleDrawCreate);
            }
            if (map.listens && map.listens('draw.delete')) {
              map.off('draw.delete', handleDrawDelete);
            }
            map.removeControl(drawRef.current);
          }
        } catch (error) {
          console.warn('Error cleaning up draw control:', error);
        }
        drawRef.current = null;
      }
      if (popupRef.current) {
        try {
          popupRef.current.remove();
        } catch (error) {
          console.warn('Error removing popup:', error);
        }
        popupRef.current = null;
      }
    };
  }, [handleDrawCreate, handleDrawDelete]);

  // Updated handleClusterChange to enable search immediately
  const handleClusterChange = (count) => {
    setUseSmartClusters(false);
    setSelectedCluster(count);
  };

  // Add this function to help with data type checking
  const isSmartClusterData = (data) => {
    return data && typeof data === 'object' && 'cluster_analyses' in data;
  };

  // Updated handleSearchClick
  const handleSearchClick = async () => {
    if (!selectedLocation || (!selectedCluster && !useSmartClusters)) {
      setError('Please select either clusters or new construction');
      return;
    }

    setIsUpdatingMarkers(true);
    setError(null);
    // Reset custom summary when searching
    setIsCustomSummary(false);

    try {
      if (useSmartClusters) {
        // Include selectedDistance in the query here
        const response = await api.get(
          `/api/get-smart-clusters?locationId=${selectedLocation}&distance=${selectedDistance}`
        );
        console.log('Smart clusters response:', response.data);
        
        // For smart clusters, we want to pass the entire response data
        setClusterData(response.data);

        // Transform listings for the listings table and markers
        const smartListings = Object.entries(response.data.cluster_analyses || {})
          .reduce((acc, [clusterKey, cluster]) => {
            if (cluster.listings && Array.isArray(cluster.listings)) {
              const clusterNumber = parseInt(clusterKey.split(' ')[1]);

              const transformedListings = cluster.listings.map(listing => ({
                ...listing,
                cluster: clusterNumber,
                listing_type: listing.listing_type || 'N/A', // Default to 'mls' if not specified
                price: listing.price || listing.sale_amount || 'N/A',
                address: listing.formatted_address || listing.address,
                latitude: listing.latitude,
                longitude: listing.longitude,
                status: listing.status || '',
                square_footage: listing.bldg_sq_ft || listing.square_footage,
                bedrooms: listing.beds || listing.bedrooms,
                bathrooms: listing.bath || listing.bathrooms,
                year_built: listing.year_built,
                property_type: listing.property_type,
                lot_size: listing.lot_size_sq_ft || listing.lot_size
              }));
              return [...acc, ...transformedListings];
            }
            return acc;
          }, []);

        setTransformedListings(smartListings);

        // Clear existing markers
        clearMapMarkers();
        
        if (smartListings.length > 0) {
          const transformedData = transformListingsData(smartListings);
          if (mapRef.current) {
            await addMarkersToMap(transformedData, mapRef.current);
          }
        }
      } else {
        // Log the request parameters
        console.log('Sending cluster data request with params:', {
          locationId: selectedLocation,
          distance: selectedDistance,
          clusters: selectedCluster
        });

        const response = await api.get('/api/get-cluster-data', {
          params: {
            locationId: selectedLocation,
            distance: selectedDistance,
            clusters: selectedCluster
          }
        });

        // Log the complete response
        console.log('Cluster data API response:', {
          status: response.status,
          statusText: response.statusText,
          data: response.data,
          listings_table: {
            exists: Boolean(response.data.listings_table),
            count: response.data.listings_table?.length
          },
          cluster_summary: {
            exists: Boolean(response.data.cluster_summary),
            count: response.data.cluster_summary?.length
          }
        });

        if (response.data.listings_table && response.data.listings_table.length > 0) {
          console.log('Listings table:', response.data.listings_table);
          const transformedListingsData = transformListingsData(response.data.listings_table);
          setTransformedListings(transformedListingsData);
          
          if (mapRef.current && mapRef.current.loaded()) {
            await addMarkersToMap(transformedListingsData, mapRef.current);
          }
        } else {
          setTransformedListings([]);
          clearMapMarkers();
        }

        if (response.data.cluster_summary && response.data.cluster_summary.length > 0) {
          const transformedClusterData = transformClusterData(response.data.cluster_summary);
          setClusterData(transformedClusterData);
        } else {
          setClusterData([]);
        }
      }

      // Reset custom cluster data when switching
      setCustomClusterData(null);
      setIsCustomSummary(false);
      
    } catch (error) {
      console.error('Error during search:', error);
      setError('An error occurred during the search');
      setTransformedListings([]);
      setClusterData([]);
      clearMapMarkers();
    } finally {
      setIsUpdatingMarkers(false);
    }
  };

  // handleInactiveToggle
  const handleInactiveToggle = useCallback(() => {
    if (isUpdatingMarkers) return;
    setShowInactive(!showInactive);
    
    // Update marker visibility based on status
    const markers = document.querySelectorAll('.marker');
    markers.forEach(marker => {
      const status = marker.dataset.status?.toLowerCase() || '';
      const isActive = status.includes('for sale') || status.includes('active');
      
      if (!isActive) {
        // If not active/for sale, toggle visibility based on showInactive state
        marker.style.display = showInactive ? 'none' : 'block';
      }
    });
  }, [isUpdatingMarkers, showInactive]);

  // handleListingSelect
  const handleListingSelect = useCallback(async (address) => {
    setCustomClusterListings(prev => {
      const newSet = new Set(prev);
      if (newSet.has(address)) {
        newSet.delete(address);
      } else {
        newSet.add(address);
      }
      
      setTimeout(async () => {
        console.log('Getting custom cluster data for addresses:', Array.from(newSet));
        const clusterData = await getCustomClusterData(newSet);
        if (clusterData) {
          setCustomClusterData(clusterData);
        }
      }, 0);

      return newSet;
    });
  }, [getCustomClusterData]);

  // toggleSelectMode
  const toggleSelectMode = useCallback(() => {
    const newSelectMode = !isSelectMode;
    setIsSelectMode(newSelectMode);

    if (newSelectMode) {
      if (!drawRef.current && mapRef.current) {
        try {
          const draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
              polygon: true,
              trash: true
            },
            defaultMode: 'draw_polygon'
          });

          mapRef.current.addControl(draw);
          drawRef.current = draw;

          if (mapRef.current) {
            console.log('Adding draw.create event listener');
            mapRef.current.on('draw.create', handleDrawCreate);
            mapRef.current.on('draw.delete', handleDrawDelete);
          }
        } catch (error) {
          console.warn('Error initializing draw mode:', error);
          setIsSelectMode(false);
        }
      }
    } else {
      // Remove draw control when exiting select mode
      if (drawRef.current && mapRef.current) {
        console.log('Removing draw controls and event listeners');
        mapRef.current.off('draw.create', handleDrawCreate);
        mapRef.current.off('draw.delete', handleDrawDelete);
        mapRef.current.removeControl(drawRef.current);
        drawRef.current = null;
      }
    }
  }, [isSelectMode, handleDrawCreate, handleDrawDelete]);

  useEffect(() => {
    console.log('customClusterListings updated:', Array.from(customClusterListings));
  }, [customClusterListings]);

  useEffect(() => {
    console.log('mapMarkers state updated, count:', mapMarkersRef.current.length);
  }, [mapMarkersRef]);

  // handleSort
  const handleSort = useCallback((column, sortDirection) => {
    setSortField(column.name);
    setSortDirection(sortDirection);
  }, []);

  // Add this helper function near the top with other helper functions
  const isSmartClustersActive = (useSmartClusters, clusterData) => {
    return useSmartClusters || isSmartClusterData(clusterData);
  };

  // Add this effect to handle custom cluster data updates
  useEffect(() => {
    if (customClusterData && isCustomSummary) {
      // Force re-render of charts when custom cluster data changes
      const chartContainer = document.querySelector('.cluster-summary-section');
      if (chartContainer) {
        // Trigger a reflow
        chartContainer.style.display = 'none';
        // Force browser to acknowledge the change
        void chartContainer.offsetHeight;
        chartContainer.style.display = '';
      }
    }
  }, [customClusterData, isCustomSummary]);

  // Modify toggleOffMarket to work with existing markers
  const toggleOffMarket = useCallback(() => {
    const newShowOffMarket = !showOffMarket;
    setShowOffMarket(newShowOffMarket);
    
    // Update visibility of off-market markers
    mapMarkersRef.current.forEach(marker => {
      const el = marker.getElement();
      if (el.dataset.isOffMarket === 'true') {
        el.style.display = newShowOffMarket ? 'block' : 'none';
      }
    });
  }, [showOffMarket]);

  // Add a new useEffect to trigger search when component mounts
  useEffect(() => {
    // Only run this effect once when the component mounts and selectedLocation is available
    if (selectedLocation) {
      // Small delay to ensure everything is initialized
      const timer = setTimeout(() => {
        // Instead of calling handleSearchClick directly, we'll use the current state values
        // to perform the search operation
        const searchWithCurrentValues = async () => {
          if (!selectedLocation || (!selectedCluster && !useSmartClusters)) {
            setError('Please select either clusters or new construction');
            return;
          }
          
          setIsUpdatingMarkers(true);
          setError(null);
          setIsCustomSummary(false);
          
          try {
            // Rest of the search logic copied from handleSearchClick
            if (useSmartClusters) {
              const response = await api.get(
                `/api/get-smart-clusters?locationId=${selectedLocation}&distance=${selectedDistance}`
              );
              setClusterData(response.data);
              
              const smartListings = Object.entries(response.data.cluster_analyses || {})
                .reduce((acc, [clusterKey, cluster]) => {
                  if (cluster.listings && Array.isArray(cluster.listings)) {
                    const clusterNumber = parseInt(clusterKey.split(' ')[1]);
                    
                    const transformedListings = cluster.listings.map(listing => ({
                      ...listing,
                      cluster: clusterNumber,
                      listing_type: listing.listing_type || 'N/A',
                      price: listing.price || listing.sale_amount || 'N/A',
                      address: listing.formatted_address || listing.address,
                      latitude: listing.latitude,
                      longitude: listing.longitude,
                      status: listing.status || '',
                      square_footage: listing.bldg_sq_ft || listing.square_footage,
                      bedrooms: listing.beds || listing.bedrooms,
                      bathrooms: listing.bath || listing.bathrooms,
                      year_built: listing.year_built,
                      property_type: listing.property_type,
                      lot_size: listing.lot_size_sq_ft || listing.lot_size
                    }));
                    return [...acc, ...transformedListings];
                  }
                  return acc;
                }, []);
              
              setTransformedListings(smartListings);
              clearMapMarkers();
              
              if (smartListings.length > 0) {
                const transformedData = transformListingsData(smartListings);
                if (mapRef.current) {
                  await addMarkersToMap(transformedData, mapRef.current);
                }
              }
            } else {
              const response = await api.get('/api/get-cluster-data', {
                params: {
                  locationId: selectedLocation,
                  distance: selectedDistance,
                  clusters: selectedCluster
                }
              });
              
              if (response.data.listings_table && response.data.listings_table.length > 0) {
                const transformedListingsData = transformListingsData(response.data.listings_table);
                setTransformedListings(transformedListingsData);
                
                if (mapRef.current && mapRef.current.loaded()) {
                  await addMarkersToMap(transformedListingsData, mapRef.current);
                }
              } else {
                setTransformedListings([]);
                clearMapMarkers();
              }
              
              if (response.data.cluster_summary && response.data.cluster_summary.length > 0) {
                const transformedClusterData = transformClusterData(response.data.cluster_summary);
                setClusterData(transformedClusterData);
              } else {
                setClusterData([]);
              }
            }
            
            setCustomClusterData(null);
            setIsCustomSummary(false);
            
          } catch (error) {
            console.error('Error during initial search:', error);
            setError('An error occurred during the search');
            setTransformedListings([]);
            setClusterData([]);
            clearMapMarkers();
          } finally {
            setIsUpdatingMarkers(false);
          }
        };
        
        searchWithCurrentValues();
      }, 500);
      
      return () => clearTimeout(timer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]); // Only depend on selectedLocation, and use eslint-disable to avoid the warning

  // Add this function to toggle the dropdown
  const toggleDropdown = useCallback(() => {
    setShowDropdown(prev => !prev);
  }, []);

  // Add this useEffect to handle clicking outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdownContainer = document.querySelector('.visibility-dropdown-container');
      if (dropdownContainer && !dropdownContainer.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  // The main render
  return (
    <div className="clustered-listings-container">
      <ComponentDescription 
        title="Listings Evaluator"
        description="helps you analyze real estate properties by grouping similar listings into clusters based on location. This tool also allows for easier visualization of market patterns, highlights comparable properties, and provides detailed statistical insights at a cluster level."
        features={[
          "Use the \"Apply Listings Filters\" button on the top right of the screen to configure the type of listings you want to see.",
          "The New Construction search will group communities that have been built in the last 3 years and will filter out listings that don't appear to be a part of a community.",
          "After searching with either Number of Clusters or New Construction, you can use the dropdown arrow in the Cluster Summary section to view detailed analysis charts.",
          "Use the Custom Cluster button to draw a polygon around a set of listings to get analysis of listings of your own custom choosing."
        ]}
      />

      {/* Clustering controls */}
      <h3 className="clustering-controls-title">Clustering Options</h3>
      <div className="clustered-listings-buttons">
        <button
          className={!useSmartClusters && selectedCluster === 5 ? 'active' : ''}
          onClick={() => handleClusterChange(5)}
        >
          5 Clusters
        </button>
        <button
          className={!useSmartClusters && selectedCluster === 10 ? 'active' : ''}
          onClick={() => handleClusterChange(10)}
        >
          10 Clusters
        </button>
        <button
          className={!useSmartClusters && selectedCluster === 15 ? 'active' : ''}
          onClick={() => handleClusterChange(15)}
        >
          15 Clusters
        </button>
        
        {/* Add a separator div */}
        <div className="button-separator"></div>
        
        <button
          onClick={() => {
            setUseSmartClusters(prev => !prev);
            setSelectedCluster(null);
          }}
          className={useSmartClusters ? 'active' : ''}
        >
          New Construction
        </button>
        
        <button
          onClick={handleSearchClick}
          className={`search-button ${isUpdatingMarkers ? 'loading' : ''}`}
          disabled={isUpdatingMarkers || (!selectedCluster && !useSmartClusters)}
        >
          {isUpdatingMarkers ? 'Loading...' : 'Search'}
        </button>

        {/* Replace the separate buttons with a dropdown */}
        <div className="visibility-dropdown-container">
          <button 
            onClick={toggleDropdown}
            className="visibility-dropdown-button"
          >
            Visibility Options <span className="dropdown-arrow">▼</span>
          </button>
          
          {showDropdown && (
            <div className="visibility-dropdown-menu">
              <label className="visibility-option">
                <input 
                  type="checkbox" 
                  checked={showInactive}
                  onChange={handleInactiveToggle}
                  disabled={isSmartClustersActive(useSmartClusters, clusterData)}
                />
                <span className={isSmartClustersActive(useSmartClusters, clusterData) ? 'disabled-text' : ''}>
                  Show Inactive Listings
                </span>
              </label>
              
              <label className="visibility-option">
                <input 
                  type="checkbox" 
                  checked={showOffMarket}
                  onChange={toggleOffMarket}
                  disabled={!isOffMarketButtonEnabled}
                />
                <span className={!isOffMarketButtonEnabled ? 'disabled-text' : ''}>
                  Show Off-Market Listings
                </span>
              </label>
            </div>
          )}
        </div>

        <button
          onClick={toggleSelectMode}
          className={`select-mode-button ${isSelectMode ? 'active' : ''} ${isSmartClustersActive(useSmartClusters, clusterData) ? 'disabled' : ''}`}
          disabled={isSmartClustersActive(useSmartClusters, clusterData)}
        >
          {isSelectMode ? 'Cancel Selection' : 'Custom Cluster'}
        </button>
      </div>

      {error && (
        <div className="error-message" style={{ color: 'red', marginBottom: '20px' }}>
          {error}
        </div>
      )}

      <div className="map-and-summary-container">
        <div className="map-section">
          <div id="map" className="map"></div>
        </div>

        <div className="cluster-summary-section">
          <div style={{ position: 'relative' }}>
            <div style={{
              position: 'absolute',
              top: '8px',
              right: '16px',
              zIndex: 1,
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}>
              <span style={{ 
                fontSize: '0.9rem', 
                color: '#666',
                userSelect: 'none'
              }}>
                Custom
              </span>
              <label className="switch" style={{
                position: 'relative',
                display: 'inline-block',
                width: '40px',
                height: '20px'
              }}>
                <input
                  type="checkbox"
                  checked={isCustomSummary}
                  onChange={() => setIsCustomSummary(prev => !prev)}
                  disabled={!customClusterData}
                  style={{ opacity: 0, width: 0, height: 0 }}
                />
                <span className="slider round" style={{
                  position: 'absolute',
                  cursor: customClusterData ? 'pointer' : 'not-allowed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: isCustomSummary ? '#fd9245' : '#ccc',
                  transition: '0.4s',
                  borderRadius: '34px',
                  opacity: customClusterData ? 1 : 0.5
                }}/>
              </label>
            </div>

            <ClusterSummary
              clusterData={clusterData}
              customClusterData={customClusterData}
              isCustomSummary={isCustomSummary}
              expandedRows={expandedRows}
              handleRowExpand={handleRowExpand}
              showListingsDetails={showListingsDetails}
              setShowListingsDetails={setShowListingsDetails}
              getClusterColor={getClusterColor}
              formatNumberWithK={formatNumberWithK}
              formatNumberInt={formatNumberInt}
              useSmartClusters={useSmartClusters || isSmartClusterData(clusterData)}
              isLoading={isUpdatingMarkers}
            />
          </div>
        </div>
      </div>

      <div className="listings-container" style={{ marginTop: '20px' }}>
        <ListingsDataTable
          transformedListings={transformedListings}
          customClusterListings={customClusterListings}
          handleListingSelect={handleListingSelect}
          getClusterColor={getClusterColor}
          formatNumberWithK={formatNumberWithK}
          formatNumberInt={formatNumberInt}
          handleSort={handleSort}
          sortField={sortField}
          sortDirection={sortDirection}
          useSmartClusters={useSmartClusters || isSmartClusterData(clusterData)}
        />
      </div>

      <div style={{ margin: '20px 0' }}></div>
    </div>
  );
};

export default ClusteredListings;