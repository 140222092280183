// src/components/MacroEconMap.jsx

import React, { useRef, useEffect, useState, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import ColoredTableCell from './ColoredTableCell'; // Import the component
import api from '../api';
import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox GL CSS
import './MacroEconMap.css'; // Import CSS for styling
import { CircularProgress } from '@mui/material';
import './ComponentDescription.css';

// Import the existing ChartComponent
import ChartComponent from './ChartComponent';

// Import PropTypes for type checking (optional but recommended)
import PropTypes from 'prop-types';

// Set your Mapbox access token using environment variables for security
mapboxgl.accessToken =  'pk.eyJ1IjoibWljaGFlbC1ydCIsImEiOiJjbTFmY2gxZGYydjBhMmptejRkMXY3NnE4In0.HLgD2rXqws3hQ0Wr7X7PQw';


// Define chart configurations outside the component to maintain a stable reference
const chartConfigs = [
  {
    id: 'median_income',
    title: 'Median Household Income Over Time',
    endpoint: '/get-median-income-county',
    useAggregation: true
  },
  {
    id: 'resident_population',
    title: 'Resident Population Over Time',
    endpoint: '/get-resident-population-county',
    useAggregation: true
  },
  {
    id: 'subprime_population',
    title: 'Subprime Population Over Time',
    endpoint: '/get-subprime-population-county',
    useAggregation: true
  },
  {
    id: 'gdp_all_industries',
    title: 'GDP All Industries Over Time',
    endpoint: '/get-gdp-all-industries-county',
    useAggregation: true
  },
  {
    id: 'private_establishments',
    title: 'Private Establishments Over Time',
    endpoint: '/get-private-establishments-county',
    useAggregation: true
  },
  {
    id: 'county_permits',
    title: 'Building Permits for Selected County',
    endpoint: '/get_permits_for_single_county_query',
    useAggregation: true
  },
  {
    id: 'county_permit_units',
    title: 'Housing Units from Building Permits for County',
    endpoint: '/get_permit_units_for_single_county_query',
    useAggregation: true
  },
  {
    id: 'county_permit_units_percent',
    title: 'Housing Units from Building Permits as % of Population',
    endpoint: '/get_permit_units_as_percent_of_population',
    useAggregation: true
  },
  {
    id: 'county-median-listing-price',
    title: 'Median Listing Price for Selected County',
    endpoint: '/get-county-median-listing-price',
    useAggregation: false
  },
  {
    id: 'county-median-days-on-market',
    title: 'Median Days on Market for Selected County',
    endpoint: '/get-county-median-days-on-market',
    useAggregation: false
  },
  {
    id: 'get-county-active-pending-ratio',
    title: 'Active/Pending Listings Ratio for Selected County',
    endpoint: '/get-county-active-pending-ratio',
    useAggregation: true
  },
  {
    id: 'get-county-price-increase-decrease',
    title: 'Price Increases vs Decreases for Selected County',
    endpoint: '/get-county-price-increase-decrease',
    useAggregation: true
  }
];

// Add the county-city mapping after the chartConfigs constant
const countyToCityMapping = {
  "51003": ["51540"], // Albemarle County, VA -> Charlottesville City, VA
  "51005": ["51580"], // Alleghany County, VA -> Covington City, VA
  "51015": ["51790", "51820"], // Augusta County, VA -> Staunton City, VA, Waynesboro City, VA
  "51031": ["51680"], // Campbell County, VA -> Lynchburg City, VA
  "51035": ["51640"], // Carroll County, VA -> Galax City, VA
  "51053": ["51730", "51570"], // Dinwiddie County, VA -> Petersburg City, VA, Colonial Heights City, VA
  "51059": ["51600", "51610"], // Fairfax County, VA -> Fairfax City, VA, Falls Church City, VA
  "51069": ["51840"], // Frederick County, VA -> Winchester City, VA
  "51081": ["51595"], // Greensville County, VA -> Emporia City, VA
  "51089": ["51690"], // Henry County, VA -> Martinsville City, VA
  "51095": ["51830"], // James City, VA -> Williamsburg City, VA
  "51121": ["51750"], // Montgomery County, VA -> Radford City, VA
  "51143": ["51590"], // Pittsylvania County, VA -> Danville City, VA
  "51149": ["51670"], // Prince George, VA -> Hopewell City, VA
  "51153": ["51683", "51685"], // Prince William, VA -> Manassas City, VA, Manassas Park City, VA
  "51161": ["51775"], // Roanoke County, VA -> Salem City, VA
  "51163": ["51530", "51678"], // Rockbridge County, VA -> Buena Vista City, VA, Lexington City, VA
  "51165": ["51660"], // Rockingham County, VA -> Harrisonburg City, VA
  "51175": ["51620"], // Southampton County, VA -> Franklin City, VA
  "51177": ["51630"], // Spotsylvania County, VA -> Fredericksburg City, VA
  "51191": ["51520"], // Washington County, VA -> Bristol City, VA
  "51195": ["51720"], // Wise County, VA -> Norton City, VA
  "51199": ["51735"], // York County, VA -> Poquoson City, VA
};

// Create a reverse mapping (city to county)
const cityToCountyMapping = {};
Object.entries(countyToCityMapping).forEach(([countyFips, cityFipsList]) => {
  cityFipsList.forEach(cityFips => {
    cityToCountyMapping[cityFips] = countyFips;
  });
});

const MacroEconMap = ({ selectedDistance, selectedLocation, locationInfo }) => {
  console.log("MacroEconMap received props:", { selectedDistance, selectedLocation, locationInfo });
  const mapContainerRef = useRef(null); // Reference to the map container div
  const mapRef = useRef(null); // To store the map instance
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCounty, setSelectedCounty] = useState(null); // To store selected county data
  const [nearestCounties, setNearestCounties] = useState([]); // To store nearest counties data
  const [countyData, setCountyData] = useState({}); // To store local JSON data
  const [columnRanges, setColumnRanges] = useState({}); // To store min and max for each column
  const [showLegend, setShowLegend] = useState(false); // State to control legend visibility
  const [isMapLoaded, setIsMapLoaded] = useState(false); // State to track map load

  // State to manage chart data, loading, and errors for each chart
  const [charts, setCharts] = useState(
    chartConfigs.reduce((acc, chart) => {
      acc[chart.id] = { data: null, loading: false, error: null };
      return acc;
    }, {})
  );

  // Ref for the community marker
  const communityMarkerRef = useRef(null);

  // Add a resize handler to fix map rendering issues
  useEffect(() => {
    if (!mapRef.current) return;
    
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.resize();
      }
    };
    
    window.addEventListener('resize', handleResize);
    
    // Call resize once to ensure proper initial rendering
    setTimeout(() => {
      handleResize();
    }, 100);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Modify the handleCountyClick function to use useCallback with correct dependencies
  const handleCountyClick = useCallback(async (e) => {
    const feature = e.features[0];
    const fips = feature.properties.GEOID || feature.id;
    const regionName = feature.properties.region_name || 'Unknown Region';

    let mainFips = String(fips); // Convert to string
    let isAggregated = false;
    const relatedRegions = []; // Use an array to maintain order
    const fipsSet = new Set(); // Use a Set to track unique FIPS codes

    // Add the primary region first
    const isCountyFips = countyToCityMapping[mainFips] !== undefined;
    const isCityFips = cityToCountyMapping[mainFips] !== undefined;
    const isCityNameWithCountyFips = regionName.includes("City") && isCountyFips;

    if (isCityFips && !isCityNameWithCountyFips) {
      // Case 1: Clicked on a city within a county
      isAggregated = true;

      // IMPORTANT: Add to fipsSet BEFORE adding to relatedRegions
      fipsSet.add(mainFips);
      
      // Add the clicked city as the primary region
      relatedRegions.push({
        fips: mainFips,
        name: regionName,
        type: 'City'
      });

      // Add the corresponding county
      const countyFips = String(cityToCountyMapping[mainFips]); // Convert to string
      fipsSet.add(countyFips);
      
      const countyInfo = countyData[countyFips];
      const countyName = countyInfo ? countyInfo.region_name : 'Unknown County';
      relatedRegions.push({
        fips: countyFips,
        name: countyName,
        type: 'County'
      });

      // Add other cities in the same county
      if (countyToCityMapping[countyFips]) {
        countyToCityMapping[countyFips].forEach(cityFips => {
          const cityFipsStr = String(cityFips); // Convert to string
          if (!fipsSet.has(cityFipsStr)) { // Only add if not already present
            fipsSet.add(cityFipsStr);
            
            const cityInfo = countyData[cityFipsStr];
            const cityName = cityInfo ? cityInfo.region_name : `City (FIPS: ${cityFipsStr})`;
            relatedRegions.push({
              fips: cityFipsStr,
              name: cityName,
              type: 'City'
            });
          }
        });
      }
    } else if (isCountyFips) {
      // Case 2: Clicked on a county
      isAggregated = true;

      fipsSet.add(mainFips);
      
      const primaryType = regionName.includes("City") ? 'City' : 'County';
      relatedRegions.push({
        fips: mainFips,
        name: regionName,
        type: primaryType
      });

      // Add related cities
      if (countyToCityMapping[mainFips]) {
        countyToCityMapping[mainFips].forEach(cityFips => {
          const cityFipsStr = String(cityFips); // Convert to string
          if (!fipsSet.has(cityFipsStr)) {
            fipsSet.add(cityFipsStr);
            
            const cityInfo = countyData[cityFipsStr];
            const cityName = cityInfo ? cityInfo.region_name : `City (FIPS: ${cityFipsStr})`;
            relatedRegions.push({
              fips: cityFipsStr,
              name: cityName,
              type: 'City'
            });
          }
        });
      }
    } else if (regionName.includes("City")) {
      // Case 3: Independent city
      isAggregated = false; // Independent cities don't aggregate
      
      fipsSet.add(mainFips);
      
      relatedRegions.push({
        fips: mainFips,
        name: regionName,
        type: 'City'
      });
    }

    try {
      const response = await api.get('/api/get-nearest-counties', {
        params: { fips: mainFips },
        withCredentials: true,
      });

      if (response.data.nearest_counties) {
        setNearestCounties(response.data.nearest_counties);
        setSelectedCounty({
          fips: mainFips,
          region_name: regionName,
        });
        setShowingAggregatedData(isAggregated);
        setAggregatedRegions(relatedRegions);
      }
    } catch (error) {
      console.error('Error fetching nearest counties:', error);
      setError(error.response?.data?.error || 'Failed to fetch nearest counties');
    }
  }, [countyData]);

  // Update the useEffect that adds the click event listener
  useEffect(() => {
    if (!countyData || Object.keys(countyData).length === 0) {
      console.log("County data not loaded yet.");
      return;
    }

    if (mapRef.current) {
      console.log("Map is already initialized.");
      return;
    }

    try {
      // Initialize Mapbox map
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/light-v11',
        center: [-98, 38.88], // Default center
        zoom: 3,
        width: '100%',
        height: '100%',
        preserveDrawingBuffer: true
      });

      // Add zoom controls
      mapRef.current.addControl(new mapboxgl.NavigationControl());

      mapRef.current.on('load', async () => {
        console.log("Map has loaded.");
        setIsMapLoaded(true); // Set map as loaded

        try {
          // Fetch GeoJSON data
          const geoJsonResponse = await fetch('https://raw.githubusercontent.com/plotly/datasets/master/geojson-counties-fips.json');
          const geojson = await geoJsonResponse.json();

          // Merge your data with the GeoJSON features
          geojson.features.forEach(feature => {
            const fips = feature.properties.GEOID || feature.id;

            if (countyData[fips]) {
              feature.properties = {
                ...feature.properties,
                ...countyData[fips]
              };
            } else {
              feature.properties.final_index_value = 'NA';
              feature.properties.final_index_percentile = 'NA';
              feature.properties.region_name = 'Unknown Region';
              // ... set other NA values ...
            }
          });

          // Add the source
          mapRef.current.addSource('counties', {
            type: 'geojson',
            data: geojson,
          });

          // Add the layer with original styling
          mapRef.current.addLayer({
            id: 'counties-layer',
            type: 'fill',
            source: 'counties',
            paint: {
              'fill-color': [
                'case',
                ['==', ['get', 'final_index_percentile'], 'NA'],
                '#cccccc',
                [
                  'interpolate',
                  ['linear'],
                  ['get', 'final_index_percentile'],
                  0, '#ffffcc',
                  20, '#c7e9b4',
                  40, '#7fcdbb',
                  60, '#41b6c4',
                  80, '#1d91c0',
                  100, '#225ea8',
                ],
              ],
              'fill-opacity': 0.75,
              'fill-outline-color': '#ffffff',
            }
          });

          // Create popup for hover
          const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
          });

          // Add hover effect
          mapRef.current.on('mousemove', 'counties-layer', (e) => {
            mapRef.current.getCanvas().style.cursor = 'pointer';
            const feature = e.features[0];

            if (typeof feature.properties.final_index_value === 'number') {
              const htmlContent = `
                <strong>${feature.properties.region_name}</strong><br/>
                Realtorch Index Percentile: ${Math.round(feature.properties.final_index_percentile)}%<br/>
                Population Percentile: ${Math.round(feature.properties.population_harmonic_mean_percentile)}%<br/>
                GDP Percentile: ${Math.round(feature.properties.gdp_harmonic_mean_percentile)}%<br/>
                Establishments Percentile: ${Math.round(feature.properties.establishments_harmonic_mean_percentile)}%<br/>
                Income Percentile: ${Math.round(feature.properties.income_harmonic_mean_percentile)}%<br/>
                Employment Percentile: ${Math.round(feature.properties.employment_harmonic_mean_percentile)}%
              `;
              popup.setLngLat(e.lngLat).setHTML(htmlContent).addTo(mapRef.current);
            }
          });

          // Remove hover effects
          mapRef.current.on('mouseleave', 'counties-layer', () => {
            mapRef.current.getCanvas().style.cursor = '';
            popup.remove();
          });

          // Add click event listener
          mapRef.current.on('click', 'counties-layer', handleCountyClick);

          setIsLoading(false);
          setShowLegend(true);

        } catch (error) {
          console.error('Error loading data:', error);
          setError(error);
          setIsLoading(false);
        }
      });

    } catch (err) {
      console.error("Error initializing map:", err);
      setError(err);
      setIsLoading(false);
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [countyData, handleCountyClick]); // Add handleCountyClick to the dependency array

  // Add community marker and zoom into it whenever locationInfo change
  useEffect(() => {
    const addMarker = () => {
      try {
        // Remove any existing marker
        if (communityMarkerRef.current) {
          communityMarkerRef.current.remove();
        }

        console.log('Adding marker at:', locationInfo);

        // Create the marker and attach a popup
        const popupContent = `
          <div style="padding: 8px;">
            <div style="font-weight: bold; margin-bottom: 4px;">
              Your Community
            </div>
          </div>
        `;
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          offset: [0, 15],
          anchor: 'top',
        }).setHTML(popupContent);

        const marker = new mapboxgl.Marker({
          color: '#E26313',
        })
          .setLngLat([locationInfo.longitude, locationInfo.latitude])
          .setPopup(popup)
          .addTo(mapRef.current);

        // Show popup on hover
        marker.getElement().addEventListener('mouseenter', () => popup.addTo(mapRef.current));
        marker.getElement().addEventListener('mouseleave', () => popup.remove());

        // Center the map on the marker
        mapRef.current.flyTo({
          center: [locationInfo.longitude, locationInfo.latitude],
          zoom: 8,
          essential: true,
          duration: 1000,
        });

        communityMarkerRef.current = marker;
      } catch (error) {
        console.error('Error adding marker:', error);
      }
    };

    if (isMapLoaded && locationInfo) {
      addMarker();
    }

    // Cleanup marker on component unmount or when dependencies change
    return () => {
      if (communityMarkerRef.current) {
        communityMarkerRef.current.remove();
      }
    };
  }, [isMapLoaded, locationInfo]);

  // Update the fetchCountyData function to properly handle the response
  useEffect(() => {
    const fetchCountyData = async () => {
      try {
        const response = await api.get('/get-macro-econ-map');
        
        // Parse the data if it's a string
        let data = response.data;
        if (typeof data === 'string') {
          try {
            data = JSON.parse(data);
          } catch (parseError) {
            console.error('Error parsing JSON response:', parseError);
            throw new Error('Failed to parse server response');
          }
        }
        
        // Debug log the data type and structure
        console.log("Data type:", typeof data);
        console.log("Is Array:", Array.isArray(data));
        console.log("Sample of data:", data?.slice?.(0, 2));

        if (!data || !Array.isArray(data)) {
          throw new Error(`Invalid response format. Expected array but got ${typeof data}`);
        }

        // Convert the array of objects into a lookup object by FIPS code
        const lookup = {};
        data.forEach(county => {
          // Ensure FIPS is padded to 5 digits
          const fips = county.fips.toString().padStart(5, '0');
          lookup[fips] = {
            fips: fips,
            region_name: county.region_name,
            population_harmonic_mean_percentile: county.population_harmonic_mean_percentile,
            gdp_harmonic_mean_percentile: county.gdp_harmonic_mean_percentile,
            establishments_harmonic_mean_percentile: county.establishments_harmonic_mean_percentile,
            income_harmonic_mean_percentile: county.income_harmonic_mean_percentile,
            employment_harmonic_mean_percentile: county.employment_harmonic_mean_percentile,
            final_index_value: county.final_index_value,
            final_index_percentile: county.final_index_percentile,
          };
        });
        
        setCountyData(lookup);
        console.log("Successfully processed data into lookup object");
      } catch (err) {
        console.error('Error fetching county data:', err);
        setError(err.message || 'Failed to fetch county data');
      }
    };

    fetchCountyData();
  }, []); // Empty dependency array ensures this runs once

  // Calculate min and max for each numerical column
  useEffect(() => {
    if (Object.keys(countyData).length === 0) return;

    const columnsToColor = [
      'population_harmonic_mean_percentile',
      'gdp_harmonic_mean_percentile',
      'establishments_harmonic_mean_percentile',
      'income_harmonic_mean_percentile',
      'employment_harmonic_mean_percentile',
      'final_index_value',
      'final_index_percentile'
    ];

    const ranges = {};

    columnsToColor.forEach(column => {
      const values = Object.values(countyData)
        .map(item => item[column])
        .filter(val => typeof val === 'number');

      if (values.length > 0) {
        const min = Math.min(...values);
        const max = Math.max(...values);
        ranges[column] = { min, max };
      } else {
        ranges[column] = { min: 0, max: 0 }; // Default values if no data
      }
    });

    setColumnRanges(ranges);
    console.log("Column ranges for color scaling:", ranges);
  }, [countyData]);

  // Add state to track if we're showing aggregated data
  const [showingAggregatedData, setShowingAggregatedData] = useState(false);
  const [aggregatedRegions, setAggregatedRegions] = useState([]);

  // Fetch chart data whenever selectedDistance or selectedCounty changes
  useEffect(() => {
    if (!selectedCounty) return; // No county selected yet

    const fetchCharts = async () => {
      const { fips } = selectedCounty;

      // Determine additional FIPS codes to include
      let additionalFips = [];
      
      // If this is a county with cities, add the city FIPS codes
      if (countyToCityMapping[fips]) {
        additionalFips = countyToCityMapping[fips];
        console.log(`Adding city FIPS codes for county ${fips}:`, additionalFips);
      } 
      // If this is a city, add its county and other cities in that county
      else if (cityToCountyMapping[fips]) {
        const countyFips = cityToCountyMapping[fips];
        // Include the county
        additionalFips.push(countyFips);
        // Include other cities in the same county (excluding the current city)
        if (countyToCityMapping[countyFips]) {
          additionalFips = [
            ...additionalFips,
            ...countyToCityMapping[countyFips].filter(cityFips => cityFips !== fips)
          ];
        }
        console.log(`Adding county and other city FIPS codes for city ${fips}:`, additionalFips);
      }
      // If we're showing aggregated data from manual selection, get FIPS codes from aggregatedRegions
      else if (showingAggregatedData && aggregatedRegions.length > 0 && 
               !countyToCityMapping[fips] && !cityToCountyMapping[fips]) {
        additionalFips = aggregatedRegions
          .filter(region => region.fips !== fips) // Exclude the primary FIPS
          .map(region => region.fips);
        console.log(`Using aggregated regions FIPS codes:`, additionalFips);
      }

      // Remove any duplicates from additionalFips
      additionalFips = [...new Set(additionalFips)];
      
      // Create an array of promises for all chart fetches
      const fetchPromises = chartConfigs.map(async (chart) => {
        // Update loading state
        setCharts((prevCharts) => ({
          ...prevCharts,
          [chart.id]: { ...prevCharts[chart.id], loading: true, error: null, data: null },
        }));

        try {
          // Prepare params with primary FIPS and any additional FIPS
          const params = { 'county-fips': fips };
          
          // Add additional FIPS codes if available
          if (chart.useAggregation && additionalFips.length > 0) {
            // Use URLSearchParams to properly format multiple values for the same parameter
            const searchParams = new URLSearchParams();
            searchParams.append('county-fips', fips);
            
            // Add each additional FIPS
            additionalFips.forEach(additionalFips => {
              searchParams.append('additional_fips', additionalFips);
            });
            
            // If the chart requires distance, add it
            if (chart.requiresDistance) {
              searchParams.append('distance', selectedDistance);
            }
            
            // Make API request with the formatted search params
            const chartResponse = await api.get(`${chart.endpoint}?${searchParams.toString()}`);
            
            // Assuming chartResponse.data is a stringified JSON
            // Set chartData to match ChartComponent's expected prop structure
            setCharts((prevCharts) => ({
              ...prevCharts,
              [chart.id]: { ...prevCharts[chart.id], data: { chart_js_json: chartResponse.data }, loading: false },
            }));
          } else {
            // No additional FIPS, use the standard approach
            if (chart.requiresDistance) {
              params.distance = selectedDistance;
            }

            const chartResponse = await api.get(chart.endpoint, {
              params: params,
            });

            // Assuming chartResponse.data is a stringified JSON
            // Set chartData to match ChartComponent's expected prop structure
            setCharts((prevCharts) => ({
              ...prevCharts,
              [chart.id]: { ...prevCharts[chart.id], data: { chart_js_json: chartResponse.data }, loading: false },
            }));
          }
        } catch (error) {
          console.error(`Error fetching chart data for ${chart.id}:`, error);
          setCharts((prevCharts) => ({
            ...prevCharts,
            [chart.id]: {
              ...prevCharts[chart.id],
              error: error.response?.data?.error || 'Failed to load chart data.',
              loading: false,
            },
          }));
        }
      });

      // Wait for all chart fetches to complete
      await Promise.all(fetchPromises);
    };

    fetchCharts();
  }, [selectedDistance, selectedCounty, showingAggregatedData, aggregatedRegions]);

  // Update the auto-select county useEffect
  useEffect(() => {
    if (!locationInfo?.fips_code || !mapRef.current || !isMapLoaded || !countyData) {
      console.log("Not ready to auto-select county:", {
        hasFipsCode: !!locationInfo?.fips_code,
        hasMap: !!mapRef.current,
        isMapLoaded,
        hasCountyData: !!countyData
      });
      return;
    }

    console.log("Auto-selecting county with FIPS:", locationInfo.fips_code);

    // Create a simulated feature object matching the structure expected by handleCountyClick
    const simulatedFeature = {
      features: [{
        properties: {
          GEOID: locationInfo.fips_code,
          region_name: countyData[locationInfo.fips_code]?.region_name || 'Unknown Region'
        }
      }]
    };

    // Call handleCountyClick with the simulated feature
    handleCountyClick(simulatedFeature);

  }, [locationInfo?.fips_code, isMapLoaded, countyData, handleCountyClick]); // Add handleCountyClick to the dependency array

  return (
    <div className="macro-container">
      {/* Add the description box above the map */}
      <div className="macro-description-wrapper">
        <div className="component-description-container">
          <div className="description-content">
            <p>The <strong>Macro Economic Index</strong> provides county-level economic analysis to help you understand broader market conditions. This tool aggregates key economic indicators including income levels, population trends, GDP growth, and building permit data.</p>
            <p>Counties are color-coded based on our proprietary RealTorch Index, which combines multiple economic factors to identify strong markets for real estate investment. Click on any county to view detailed metrics and charts.</p>
          </div>
        </div>
      </div>
      
      {/* Map and table container */}
      <div className="macro-content-wrapper">
        <div className="map-container" ref={mapContainerRef}>
          {isLoading && (
            <div className="overlay">
              <CircularProgress size={40} style={{ color: '#E26313' }} />
              <p style={{ marginLeft: '10px' }}>Loading map...</p>
            </div>
          )}
          {error && (
            <div className="overlay error">
              <p>Error loading map: {error.message}</p>
            </div>
          )}
          {/* Conditionally render the legend */}
          {showLegend && (
            <div className="legend">
              {/* Create the vertical gradient bar */}
              <div className="legend-gradient"></div>
              {/* Add vertical labels */}
              <div className="legend-labels">
                {[0, 20, 40, 60, 80, 100].reverse().map(label => (
                  <span key={label}>{label}</span>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="table-container">
          {/* Display selected county and nearest counties data */}
          {selectedCounty && (
            <div className="county-info">
              <h3>Selected Region: {selectedCounty.region_name} (FIPS: {selectedCounty.fips})</h3>
              
              {/* Add information about aggregated data if applicable */}
              {showingAggregatedData && aggregatedRegions.length > 0 && (
                <div className="aggregation-notice">
                  <p><strong>Note:</strong> The statistics shown represent aggregated data for the following regions:</p>
                  <ul>
                    {/* First, find and display the county */}
                    {aggregatedRegions
                      .filter(region => region.type === 'County')
                      .map((region, index) => (
                        <li key={`county-${region.fips}`}>
                          <strong>{region.name}</strong> - County (FIPS: {region.fips})
                        </li>
                      ))
                    }
                    
                    {/* Then display all cities, using a Set to ensure uniqueness */}
                    {(() => {
                      // Create a Set of unique city FIPS codes
                      const uniqueCityFips = new Set();
                      // Get all cities with unique FIPS codes
                      return aggregatedRegions
                        .filter(region => region.type === 'City')
                        .filter(region => {
                          // Convert FIPS to string for consistent comparison
                          const fipsStr = String(region.fips);
                          if (uniqueCityFips.has(fipsStr)) {
                            return false; // Skip this city, we've already included it
                          }
                          uniqueCityFips.add(fipsStr);
                          return true;
                        })
                        .map((region, index) => (
                          <li key={`city-${region.fips}`}>
                            <strong>{region.name}</strong> - City (FIPS: {region.fips})
                          </li>
                        ));
                    })()}
                  </ul>
                  <p>Economic statistics shown in these charts represent the <strong>combined data</strong> from all regions listed above.</p>
                  <p><strong>Note:</strong> Charts showing median values (like Median Listing Price and Median Days on Market) display data only for the selected region and are not aggregated across all regions.</p>
                </div>
              )}
              
              {nearestCounties.length > 0 ? (
                <div>
                  <h4>19 Nearest Counties:</h4>
                  <table>
                    <thead>
                      <tr>
                        <th>FIPS</th>
                        <th>Region Name</th>
                        <th>Population</th>
                        <th>GDP</th>
                        <th>Establishments</th>
                        <th>Income</th>
                        <th>Employment</th>
                        <th>Realtorch Index Percentile</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nearestCounties
                        .filter(county => {
                          // Filter out cities by checking if the FIPS is in the cityToCountyMapping
                          const fipsStr = county.fips.toString().padStart(5, '0');
                          return !cityToCountyMapping[fipsStr]; // Keep only if it's NOT a city
                        })
                        .map((county, index) => {
                          const fipsStr = county.fips.toString().padStart(5, '0');
                          const countyInfo = countyData[fipsStr];
                          return (
                            <tr key={index}>
                              <td>{county.fips}</td>
                              <td>{countyInfo ? countyInfo.region_name : 'Data Not Available'}</td>
                              <ColoredTableCell
                                value={countyInfo ? Math.round(countyInfo.population_harmonic_mean_percentile) : 'NA'}
                                column="population_harmonic_mean_percentile"
                                columnRanges={columnRanges}
                              />
                              <ColoredTableCell
                                value={countyInfo ? Math.round(countyInfo.gdp_harmonic_mean_percentile) : 'NA'}
                                column="gdp_harmonic_mean_percentile"
                                columnRanges={columnRanges}
                              />
                              <ColoredTableCell
                                value={countyInfo ? Math.round(countyInfo.establishments_harmonic_mean_percentile) : 'NA'}
                                column="establishments_harmonic_mean_percentile"
                                columnRanges={columnRanges}
                              />
                              <ColoredTableCell
                                value={countyInfo ? Math.round(countyInfo.income_harmonic_mean_percentile) : 'NA'}
                                column="income_harmonic_mean_percentile"
                                columnRanges={columnRanges}
                              />
                              <ColoredTableCell
                                value={countyInfo ? Math.round(countyInfo.employment_harmonic_mean_percentile) : 'NA'}
                                column="employment_harmonic_mean_percentile"
                                columnRanges={columnRanges}
                              />
                              <ColoredTableCell
                                value={countyInfo ? Math.round(countyInfo.final_index_percentile) : 'NA'}
                                column="final_index_percentile"
                                columnRanges={columnRanges}
                              />
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No nearby counties found.</p>
              )}

              {/* Chart Sections */}
              <div className="chart-sections">
                {chartConfigs.map((chart) => (
                  <div key={chart.id} className="chart-container">
                    <h4>{chart.title}</h4>
                    {charts[chart.id].loading && <p>Loading chart...</p>}
                    {charts[chart.id].error && <p className="error">Error loading chart: {charts[chart.id].error}</p>}
                    {charts[chart.id].data && <ChartComponent chartData={charts[chart.id].data} />}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Update PropTypes
MacroEconMap.propTypes = {
  selectedDistance: PropTypes.number,
  selectedLocation: PropTypes.string,
  locationInfo: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    fips_code: PropTypes.string,
  }),
};

export default MacroEconMap;