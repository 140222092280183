import React, { useState, useEffect } from 'react';
import './ListingsFilterModal.css';
import api from '../api';

const ListingsFilterModal = ({ show, onClose, onApply, initialFilters, locationId }) => {
  console.log('Modal Render - Props received:', { show, initialFilters, locationId });

  const [priceRange, setPriceRange] = useState({
    min: '',
    max: ''
  });
  const [bedrooms, setBedrooms] = useState({
    min: '',
    max: ''
  });
  const [squareFootage, setSquareFootage] = useState({
    min: '',
    max: ''
  });
  const [yearBuilt, setYearBuilt] = useState({
    min: '',
    max: '',
    includeUnknown: false
  });
  const [homeTypes, setHomeTypes] = useState([]);

  useEffect(() => {
    console.log('useEffect triggered with initialFilters:', initialFilters);
    
    if (initialFilters && Object.keys(initialFilters).length > 0) {
      // Set price range
      if (initialFilters.priceRange) {
        setPriceRange({
          min: initialFilters.priceRange.min?.toString() || '',
          max: initialFilters.priceRange.max?.toString() || ''
        });
      }
      
      // Set bedrooms
      if (initialFilters.bedroomCount) {
        setBedrooms({
          min: initialFilters.bedroomCount.min?.toString() || '',
          max: initialFilters.bedroomCount.max?.toString() || ''
        });
      }
      
      // Set square footage
      if (initialFilters.squareFootage) {
        setSquareFootage({
          min: initialFilters.squareFootage.min?.toString() || '',
          max: initialFilters.squareFootage.max?.toString() || ''
        });
      }
      
      // Set year built
      if (initialFilters.yearBuilt) {
        setYearBuilt({
          min: initialFilters.yearBuilt.min?.toString() || '',
          max: initialFilters.yearBuilt.max?.toString() || '',
          includeUnknown: initialFilters.yearBuilt.includeUnknown || false
        });
      }
      
      // Set home types - ensure we're using the correct property name
      if (initialFilters.homeTypes) {
        console.log('Setting home types:', initialFilters.homeTypes);
        setHomeTypes(initialFilters.homeTypes);
      }
    }
  }, [initialFilters]);

  console.log('Current state values:', { priceRange, bedrooms, squareFootage, yearBuilt });

  const handleHomeTypeChange = (type) => {
    setHomeTypes(prev => {
      if (prev.includes(type)) {
        return prev.filter(t => t !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const handleApply = async () => {
    // Sort homeTypes alphabetically
    const sortedHomeTypes = [...homeTypes].sort();

    // Create the data for the backend
    const filterData = {
      location_id: locationId,
      priceMin: priceRange.min,
      priceMax: priceRange.max,
      bedMin: bedrooms.min,
      bedMax: bedrooms.max,
      sqftMin: squareFootage.min,
      sqftMax: squareFootage.max,
      yearMin: yearBuilt.min,
      yearMax: yearBuilt.max,
      includeUnknownYear: yearBuilt.includeUnknown,
      homeTypes: sortedHomeTypes,
    };

    // Create frontend filters structure
    const frontendFilters = {
      priceRange: { min: priceRange.min || '', max: priceRange.max || '' },
      bedroomCount: { min: bedrooms.min || '', max: bedrooms.max || '' },
      squareFootage: { min: squareFootage.min || '', max: squareFootage.max || '' },
      yearBuilt: { 
        min: yearBuilt.min || '', 
        max: yearBuilt.max || '',
        includeUnknown: yearBuilt.includeUnknown 
      },
      homeTypes: sortedHomeTypes,
    };

    try {
      const response = await api.post('/api/save-filters', filterData, {
        withCredentials: true
      });
      
      if (response.status === 200) {
        // Update both backend and frontend state
        onApply(frontendFilters);
        onClose();
      } else {
        console.error('Failed to save filters');
      }
    } catch (error) {
      console.error('Error saving filters:', error);
    }
  };

  if (!show) return null;

  return (
    <div className="listings-filter-modal modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content text-dark">
          <div className="modal-header">
            <h5 className="modal-title">Listings Filters</h5>
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            
            <div className="mb-3">
              <label className="form-label fw-bold">Price Range</label>
              <div className="d-flex">
                <input 
                  type="number" 
                  className="form-control me-2" 
                  placeholder="Min" 
                  value={priceRange.min} 
                  onChange={(e) => setPriceRange({ ...priceRange, min: e.target.value })} 
                />
                <input 
                  type="number" 
                  className="form-control" 
                  placeholder="Max" 
                  value={priceRange.max} 
                  onChange={(e) => setPriceRange({ ...priceRange, max: e.target.value })} 
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label fw-bold">Bedroom Count</label>
              <div className="d-flex">
                <input 
                  type="number" 
                  className="form-control me-2" 
                  placeholder="Min" 
                  value={bedrooms.min} 
                  onChange={(e) => setBedrooms({ ...bedrooms, min: e.target.value })} 
                />
                <input 
                  type="number" 
                  className="form-control" 
                  placeholder="Max" 
                  value={bedrooms.max} 
                  onChange={(e) => setBedrooms({ ...bedrooms, max: e.target.value })} 
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label fw-bold">Square Footage</label>
              <div className="d-flex">
                <input 
                  type="number" 
                  className="form-control me-2" 
                  placeholder="Min" 
                  value={squareFootage.min} 
                  onChange={(e) => setSquareFootage({ ...squareFootage, min: e.target.value })} 
                />
                <input 
                  type="number" 
                  className="form-control" 
                  placeholder="Max" 
                  value={squareFootage.max} 
                  onChange={(e) => setSquareFootage({ ...squareFootage, max: e.target.value })} 
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label fw-bold">Year Built</label>
              <div className="d-flex mb-2">
                <input 
                  type="number" 
                  className="form-control me-2" 
                  placeholder="Min" 
                  value={yearBuilt.min} 
                  onChange={(e) => setYearBuilt({ ...yearBuilt, min: e.target.value })} 
                />
                <input 
                  type="number" 
                  className="form-control" 
                  placeholder="Max" 
                  value={yearBuilt.max} 
                  onChange={(e) => setYearBuilt({ ...yearBuilt, max: e.target.value })} 
                />
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="includeUnknownYear"
                  checked={yearBuilt.includeUnknown}
                  onChange={(e) => setYearBuilt({ ...yearBuilt, includeUnknown: e.target.checked })}
                />
                <label className="form-check-label" htmlFor="includeUnknownYear">
                  Include properties with unknown year built
                </label>
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label fw-bold">Home Type</label>
              <div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="singleFamily"
                    checked={homeTypes.includes('single-family')}
                    onChange={() => handleHomeTypeChange('single-family')}
                  />
                  <label className="form-check-label" htmlFor="singleFamily">
                    Single Family
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="attached"
                    checked={homeTypes.includes('attached')}
                    onChange={() => handleHomeTypeChange('attached')}
                  />
                  <label className="form-check-label" htmlFor="attached">
                    Attached (Condo or Townhouse)
                  </label>
                </div>
              </div>
            </div>

          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
            <button type="button" className="btn btn-primary" onClick={handleApply}>Apply</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingsFilterModal;
