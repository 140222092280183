import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../static/rt_logo.png';
import './Navbar.css';
import ListingsFilterModal from './ListingsFilterModal';
import api from '../api';

const Navbar = ({
  user,
  clients,
  selectedClient,
  onClientChange,
  locations,
  selectedLocation,
  onLocationChange,
  selectedDistance,
  onDistanceChange,
  onLogout,
  onFiltersApply,
  listingsFilters
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(null);
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  useEffect(() => {
    const fetchFilters = async () => {
      if (!selectedLocation) return;

      try {
        const response = await api.get('/api/get-filters', {
          params: { location_id: selectedLocation },
          withCredentials: true
        });
        
        if (response.data.filters) {
          const formattedFilters = {
            priceRange: { 
              min: response.data.filters.priceMin || '', 
              max: response.data.filters.priceMax || '' 
            },
            bedroomCount: { 
              min: response.data.filters.bedMin || '', 
              max: response.data.filters.bedMax || '' 
            },
            squareFootage: { 
              min: response.data.filters.sqftMin || '', 
              max: response.data.filters.sqftMax || '' 
            },
            yearBuilt: { 
              min: response.data.filters.yearMin || '',
              max: response.data.filters.yearMax || '',
              includeUnknown: response.data.filters.includeUnknownYear ?? false
            },
            homeTypes: response.data.filters.homeTypes || []
          };
          
          setCurrentFilters(formattedFilters);
          onFiltersApply(response.data.filters);
        }
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    fetchFilters();
  }, [selectedLocation, onFiltersApply]);

  const handleLocationSelect = (event) => {
    // Just update location - the useEffect will handle filter updates
    onLocationChange(event.target.value);
  };

  const handleDistanceSelect = (event) => {
    onDistanceChange(Number(event.target.value));
  };

  const handleClientSelect = (event) => {
    onClientChange(event.target.value);
  };

  const getDistanceOptions = (selectedLocationId) => {
    const location = locations.find(loc => String(loc.id) === String(selectedLocationId));
    const baseDistances = [3, 5];
    
    // Add 8 miles option if location has extended_region
    if (location?.extended_region) {
      return [...baseDistances, 8];
    }
    return baseDistances;
  };

  return (
    <nav className="navbar navbar-expand-lg bg-light navbar-light">
      <div className="navbar-left">
        <Link to="/" className="navbar-brand">
          <img src={logo} className="img-fluid" id="brand-logo" alt="Brand Logo" />
        </Link>

        {user && locations.length > 0 && (
          <div className="selectors">
            <select 
              value={selectedLocation || ''} 
              onChange={handleLocationSelect} 
              className="location-select"
            >
              {locations.map(location => (
                <option key={location.id} value={String(location.id)}>
                  {location.name}
                </option>
              ))}
            </select>

            <select 
              value={selectedDistance} 
              onChange={handleDistanceSelect} 
              className="distance-select"
            >
              {getDistanceOptions(selectedLocation).map(distance => (
                <option key={distance} value={distance}>
                  {distance} miles
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <button
        className="navbar-toggler"
        type="button"
        onClick={() => setIsNavExpanded(!isNavExpanded)}
        aria-expanded={isNavExpanded}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      
      <div className={`collapse navbar-collapse ${isNavExpanded ? 'show' : ''}`}>
        <ul className="navbar-nav ml-auto">
          {user?.isAdmin && (
            <li className="nav-item client-select-container">
              <label htmlFor="client-select" className="mr-2 mb-0">Client:</label>
              <select 
                id="client-select" 
                value={selectedClient || ''} 
                onChange={handleClientSelect}
                className="client-select"
              >
                <option value="" disabled>Select a client</option>
                {clients.map(client => (
                  <option key={client.id} value={String(client.id)}>
                    {client.name} ({client.email})
                  </option>
                ))}
              </select>
            </li>
          )}

          {user && (
            <li className="nav-item filters-button-container">
              <button 
                className="btn btn-outline-primary"
                onClick={() => setShowFilterModal(true)}
              >
                Apply Listings Filters
              </button>
            </li>
          )}

          <div className="nav-links-container">
            {user ? (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/">App Home</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://realtorch.ai" target="_blank" rel="noopener noreferrer">Main Site</a>
                </li>
                <li className="nav-item">
                  <button className="btn btn-link nav-link" onClick={onLogout}>Logout</button>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <a className="nav-link" href="https://realtorch.ai" target="_blank" rel="noopener noreferrer">Main Site</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/login">Login</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/sign-up">Sign Up</Link>
                </li>
              </>
            )}
          </div>
        </ul>
      </div>

      <ListingsFilterModal
        key={selectedLocation}
        show={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        onApply={onFiltersApply}
        initialFilters={currentFilters}
        locationId={selectedLocation}
      />
    </nav>
  );
};

export default Navbar;
