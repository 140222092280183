import axios from 'axios';
import { auth } from './firebase'; // Adjust path to match your project structure

// Get the API URLs from environment variables
const API_URL = process.env.REACT_APP_API_URL;
const ADVISOR_API_URL = process.env.REACT_APP_ADVISOR_API_URL;

// Create an Axios instance with the base URL
const api = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Include credentials (cookies) if needed
});

// Add a request interceptor to include the Firebase token
api.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken(); // getIdToken is a method on the User object
      config.headers['Authorization'] = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Create a separate instance for the advisor API
const advisor_api = axios.create({
  baseURL: ADVISOR_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Optionally add token to advisor_api as well
advisor_api.interceptors.request.use(
  async (config) => {
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      config.headers['Authorization'] = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export { api as default, advisor_api };