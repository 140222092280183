import React, { useEffect, useRef, useState, useMemo } from 'react';
import mapboxgl from 'mapbox-gl';
import ChartComponent from './ChartComponent';
import api from '../api';
import './LocalDemographics.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { CircularProgress } from '@mui/material';
import './ComponentDescription.css';

const LocalDemographics = ({ selectedLocation, locationInfo, selectedDistance }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [demographicData, setDemographicData] = useState({
    age_data: null,
    income_data: null,
    household_size_data: null,
    mobility_by_age_data: null,
    mobility_by_tenure_data: null,
    mobility_by_income_data: null,
    tract_boundaries: null,
    errors: []
  });
  const mapContainerRef = useRef(null);
  const communityMarkerRef = useRef(null);
  const mapRef = useRef(null);
  const [temporaryLocation, setTemporaryLocation] = useState(null);
  const [isPlacingPin, setIsPlacingPin] = useState(false);
  const [isLoadingTracts, setIsLoadingTracts] = useState(false);

  // Memoize chart data
  const memoizedIncomeData = useMemo(() => {
    if (!demographicData.income_data) return null;
    
    // Create deep copies of the chart data
    const ownersData = JSON.parse(JSON.stringify(demographicData.income_data.owners));
    const rentersData = JSON.parse(JSON.stringify(demographicData.income_data.renters));
    
    // Replace the string tooltip callbacks with functions
    if (ownersData.options?.plugins?.tooltip?.callbacks?.label) {
      ownersData.options.plugins.tooltip.callbacks = {
        label: function(tooltipItem) {
          const dataset = tooltipItem.dataset;
          const index = tooltipItem.dataIndex;
          const value = tooltipItem.parsed.y;
          
          // Try to get raw value if available
          let rawValue = "N/A";
          if (dataset.rawValues && dataset.rawValues[index]) {
            rawValue = dataset.rawValues[index].raw.toLocaleString();
          }
          
          // Extract just the year part from the label
          const labelParts = dataset.label.split(' (Total:');
          const cleanLabel = labelParts[0];
          
          return cleanLabel + ": " + value.toFixed(1) + "% (" + rawValue + " households)";
        }
      };
    }
    
    if (rentersData.options?.plugins?.tooltip?.callbacks?.label) {
      rentersData.options.plugins.tooltip.callbacks = {
        label: function(tooltipItem) {
          const dataset = tooltipItem.dataset;
          const index = tooltipItem.dataIndex;
          const value = tooltipItem.parsed.y;
          
          // Try to get raw value if available
          let rawValue = "N/A";
          if (dataset.rawValues && dataset.rawValues[index]) {
            rawValue = dataset.rawValues[index].raw.toLocaleString();
          }
          
          // Extract just the year part from the label
          const labelParts = dataset.label.split(' (Total:');
          const cleanLabel = labelParts[0];
          
          return cleanLabel + ": " + value.toFixed(1) + "% (" + rawValue + " households)";
        }
      };
    }
    
    return {
      owners: ownersData,
      renters: rentersData
    };
  }, [demographicData.income_data]);
  const memoizedHouseholdSizeData = useMemo(() => {
    if (!demographicData.household_size_data) return null;
    
    // Create a deep copy of the chart data
    const chartData = JSON.parse(JSON.stringify(demographicData.household_size_data));
    
    // Replace the string tooltip callback with a function that will be properly serialized
    if (chartData.options?.plugins?.tooltip?.callbacks?.label) {
      chartData.options.plugins.tooltip.callbacks = {
        label: function(tooltipItem) {
          const dataset = tooltipItem.dataset;
          const index = tooltipItem.dataIndex;
          const value = tooltipItem.parsed.y;
          
          // Try to get raw value if available
          let rawValue = "N/A";
          if (dataset.rawValues && dataset.rawValues[index]) {
            rawValue = dataset.rawValues[index].raw.toLocaleString();
          }
          
          // Extract just the year part from the label (e.g., "Tracts 2023" from "Tracts 2023 (Total: 75.9k)")
          const labelParts = dataset.label.split(' (Total:');
          const cleanLabel = labelParts[0];
          
          return cleanLabel + ": " + value.toFixed(1) + "% (" + rawValue + " households)";
        }
      };
    }
    
    return chartData;
  }, [demographicData.household_size_data]);
  const memoizedMobilityData = useMemo(() => demographicData.mobility_by_age_data, [demographicData.mobility_by_age_data]);
  const memoizedMobilityByTenureData = useMemo(
    () => demographicData.mobility_by_tenure_data,
    [demographicData.mobility_by_tenure_data]
  );
  const memoizedMobilityByIncomeData = useMemo(
    () => demographicData.mobility_by_income_data,
    [demographicData.mobility_by_income_data]
  );

  // Add this memoized function for age data
  const memoizedAgeData = useMemo(() => {
    if (!demographicData.age_data) return null;
    
    // Create a deep copy of the chart data
    const chartData = JSON.parse(JSON.stringify(demographicData.age_data));
    
    // Replace the string tooltip callback with a function that will be properly serialized
    if (chartData.options?.plugins?.tooltip?.callbacks?.label) {
      chartData.options.plugins.tooltip.callbacks = {
        label: function(tooltipItem) {
          const dataset = tooltipItem.dataset;
          const index = tooltipItem.dataIndex;
          const value = tooltipItem.parsed.y;
          
          // Try to get raw value if available
          let rawValue = "N/A";
          if (dataset.rawValues && dataset.rawValues[index]) {
            rawValue = dataset.rawValues[index].raw.toLocaleString();
          }
          
          // Extract just the year part from the label
          const labelParts = dataset.label.split(' (Pop:');
          const cleanLabel = labelParts[0];
          
          return cleanLabel + ": " + value.toFixed(1) + "% (" + rawValue + " people)";
        }
      };
    }
    
    return chartData;
  }, [demographicData.age_data]);

  // First useEffect - Map initialization only
  useEffect(() => {
    if (!locationInfo?.latitude || !locationInfo?.longitude) return;

    mapboxgl.accessToken = 'pk.eyJ1IjoidGtpbTcxMSIsImEiOiJjbHpyNTlrc2owbnduMmxweWNwMDVwdWlyIn0.Giqxjm8xnxmEiDyDQJtXbg';

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [locationInfo.longitude, locationInfo.latitude],
      zoom: 11
    });

    mapRef.current = map;

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [locationInfo?.latitude, locationInfo?.longitude]); // Remove selectedDistance dependency

  // Update demographic data fetch
  useEffect(() => {
    const fetchDemographicData = async () => {
      const activeLocation = temporaryLocation || locationInfo;
      if (!activeLocation?.latitude || !activeLocation?.longitude) return;
      
      setIsLoading(true);
      setIsLoadingTracts(true);
      try {
        const response = await api.get('/get-all-demographic-data', {
          params: {
            latitude: activeLocation.latitude,
            longitude: activeLocation.longitude,
            distance: selectedDistance || 5
          }
        });
        console.log("Demographic Data Response:", response.data);  // Debug log
        console.log("Age Data:", response.data.age_data);         // Debug log
        setDemographicData(response.data);
        
        // Update map with tract boundaries if available
        if (response.data.tract_boundaries && mapRef.current) {
          updateMapBoundaries(response.data.tract_boundaries);
        }
      } catch (error) {
        console.error('Error fetching demographic data:', error);
        setDemographicData(prev => ({
          ...prev,
          errors: [...(prev.errors || []), 'Failed to fetch demographic data']
        }));
      } finally {
        setIsLoading(false);
        setIsLoadingTracts(false);
      }
    };

    fetchDemographicData();
  }, [locationInfo, temporaryLocation, selectedDistance]);

  // Helper function to update map boundaries
  const updateMapBoundaries = (boundariesData) => {
    if (!mapRef.current) return;

    // Remove existing layers, sources, and event listeners
    if (mapRef.current.getLayer('census-tracts-fill')) {
        mapRef.current.off('mousemove', 'census-tracts-fill');
        mapRef.current.off('mouseleave', 'census-tracts-fill');
        mapRef.current.removeLayer('census-tracts-fill');
    }
    if (mapRef.current.getLayer('census-tracts-outline')) {
        mapRef.current.removeLayer('census-tracts-outline');
    }
    if (mapRef.current.getSource('census-tracts')) {
        mapRef.current.removeSource('census-tracts');
    }

    // Add unique IDs to features if they don't have them
    const featuresWithIds = {
        type: 'FeatureCollection',
        features: boundariesData.features.map((feature, index) => ({
            ...feature,
            id: index // Explicitly add numeric ID
        }))
    };

    // Add new source with generateId disabled since we're adding our own IDs
    mapRef.current.addSource('census-tracts', {
        type: 'geojson',
        data: featuresWithIds,
        generateId: false // We're using our own IDs
    });

    // Add fill layer
    mapRef.current.addLayer({
        id: 'census-tracts-fill',
        type: 'fill',
        source: 'census-tracts',
        paint: {
            'fill-color': [
                'case',
                ['==', ['get', 'total_population_change'], null],
                '#cccccc', // Color for 'No Data'
                [
                    'interpolate',
                    ['linear'],
                    ['to-number', ['get', 'total_population_change']],
                    -700, '#B71C1C',    // Dark red for significant population loss
                    -350, '#EF5350',    // Light red for moderate population loss
                    0, '#E8E8E8',       // Light gray for neutral/no change
                    350, '#64B5F6',     // Light blue for moderate growth
                    700, '#1565C0'      // Dark blue for significant growth
                ]
            ],
            'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                0.85,
                0.7
            ]
        }
    });

    // Add outline layer
    mapRef.current.addLayer({
        id: 'census-tracts-outline',
        type: 'line',
        source: 'census-tracts',
        paint: {
            'line-color': '#009999',
            'line-width': 2
        }
    });

    // Create a popup but don't add to map yet
    const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: '350px',
        className: 'custom-popup'
    });

    let hoveredStateId = null;

    // Handle mouse enter
    mapRef.current.on('mousemove', 'census-tracts-fill', (e) => {
        if (e.features.length > 0) {
            const feature = e.features[0];
            
            if (hoveredStateId !== null) {
                mapRef.current.setFeatureState(
                    { source: 'census-tracts', id: hoveredStateId },
                    { hover: false }
                );
            }

            hoveredStateId = feature.id;
            
            mapRef.current.setFeatureState(
                { source: 'census-tracts', id: hoveredStateId },
                { hover: true }
            );

            const properties = feature.properties;
            
            // Safely format population with null checks
            const population = properties.population != null 
                ? parseInt(properties.population).toLocaleString()
                : 'No data';
            const year = properties.population_year || 'N/A';
            const distance = properties.distance_mi != null 
                ? parseFloat(properties.distance_mi).toFixed(1) 
                : 'N/A';

            // Create popup HTML
            const popupHtml = `
                <style>
                    .custom-popup .mapboxgl-popup-content {
                        width: 350px;
                        max-width: 350px;
                        word-wrap: break-word;
                        white-space: normal;
                    }
                </style>
                <div style="padding: 10px;">
                    <h4 style="margin: 0 0 8px 0;">Tract ${properties.NAME || 'Unknown'}</h4>
                    <p style="margin: 4px 0;">Population (${year}): ${population}</p>
                    <p style="margin: 4px 0;">Population Change (${properties.earliest_year}-${year}): ${properties.total_population_change > 0 ? '+' : ''}${properties.total_population_change.toLocaleString()}</p>
                    <p style="margin: 4px 0;">Avg. Annual Growth: ${properties.avg_growth_rate > 0 ? '+' : ''}${properties.avg_growth_rate.toFixed(1)}%</p>
                    <p style="font-size: 0.8em; color: #666; margin: 4px 0;">*Annual growth rates calculated year-over-year from ${properties.earliest_year} to ${year}</p>
                    <p style="margin: 4px 0;">Distance: ${distance} mi</p>
                </div>
            `;

            // Position the popup
            popup
                .setLngLat(e.lngLat)
                .setHTML(popupHtml)
                .addTo(mapRef.current);
        }
    });

    // Handle mouse leave
    mapRef.current.on('mouseleave', 'census-tracts-fill', () => {
        if (hoveredStateId !== null) {
            mapRef.current.setFeatureState(
                { source: 'census-tracts', id: hoveredStateId },
                { hover: false }
            );
        }
        hoveredStateId = null;
        popup.remove();
    });
  };

  // Keep the marker useEffect separate
  useEffect(() => {
    const addMarker = () => {
      try {
        if (communityMarkerRef.current) {
          communityMarkerRef.current.remove();
        }

        const popupContent = `
          <div style="padding: 8px;">
            <div style="font-weight: bold; margin-bottom: 4px;">
              Your Community
            </div>
          </div>
        `;

        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false,
          offset: [0, 15],
          anchor: 'top',
        }).setHTML(popupContent);

        const marker = new mapboxgl.Marker({
          color: '#E26313',
        })
          .setLngLat([locationInfo.longitude, locationInfo.latitude])
          .setPopup(popup)
          .addTo(mapRef.current);

        marker.getElement().addEventListener('mouseenter', () => popup.addTo(mapRef.current));
        marker.getElement().addEventListener('mouseleave', () => popup.remove());

        communityMarkerRef.current = marker;
      } catch (error) {
        console.error('Error adding marker:', error);
      }
    };

    if (mapRef.current && locationInfo?.latitude && locationInfo?.longitude) {
      addMarker();
    }

    return () => {
      if (communityMarkerRef.current) {
        communityMarkerRef.current.remove();
      }
    };
  }, [locationInfo?.latitude, locationInfo?.longitude]);

  // Add this near the start of the component, after other useEffects
  useEffect(() => {
    if (!mapRef.current) return;

    const handleMapClick = (e) => {
      if (!isPlacingPin) return;

      // Remove existing temporary marker if any
      if (communityMarkerRef.current) {
        communityMarkerRef.current.remove();
      }

      // Create new marker at clicked location
      const newLocation = {
        latitude: e.lngLat.lat,
        longitude: e.lngLat.lng
      };
      setTemporaryLocation(newLocation);
      setIsPlacingPin(false);

      // Add marker
      const marker = new mapboxgl.Marker({
        color: '#E26313',
      })
        .setLngLat([newLocation.longitude, newLocation.latitude])
        .addTo(mapRef.current);

      communityMarkerRef.current = marker;
    };

    mapRef.current.on('click', handleMapClick);

    return () => {
      if (mapRef.current) {
        mapRef.current.off('click', handleMapClick);
      }
    };
  }, [isPlacingPin]);

  if (!locationInfo) {
    return <div className="local-demographics-container">Please select a location to view local demographics data.</div>;
  }

  return (
    <div className="local-demographics-container">
      {/* Description box above the map */}
      <div className="demographics-description-wrapper">
        <div className="component-description-container">
          <div className="description-content">
            <p>The <strong>Local Demographics</strong> tool allows you to view the local demographics of any area in the United States. This is retrieved by getting all the nearby tracts based on the user's selected radius and aggregating the figures between all those tracts.</p>
            <p>To fetch data on another area, click <strong>"Drop new pin"</strong> on the top right of the map and click anywhere on the map.</p>
          </div>
        </div>
      </div>
      
      {/* Map and charts section */}
      <div className="demographics-content-wrapper">
        <div ref={mapContainerRef} className="demographics-map-container">
          {isLoadingTracts && (
            <div className="loading-overlay">
              <div className="loading-content">
                <CircularProgress size={40} style={{ color: '#E26313' }} />
                <p>Fetching tract details...</p>
              </div>
            </div>
          )}
          <button
            className="drop-pin-button"
            onClick={() => setIsPlacingPin(true)}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 1,
              padding: '8px 16px',
              backgroundColor: '#E26313',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            {isPlacingPin ? 'Click on map to place pin' : 'Drop new pin'}
          </button>
        </div>
        
        <div className="charts-section">
          <h4>Local Demographics within {selectedDistance} miles of your community</h4>
          <h5>The data on this page is based on the census tracts highlighted on the map.</h5>
          
          {isLoading ? (
            <div className="loading-container">
              <CircularProgress size={60} style={{ color: '#E26313' }} />
            </div>
          ) : (
            <>
              {/* Age Distribution charts */}
              {memoizedAgeData && (
                <div className="chart-wrapper">
                  <ChartComponent chartData={memoizedAgeData} />
                </div>
              )}

              {/* Household Size chart */}
              {memoizedHouseholdSizeData && (
                <div className="chart-wrapper">
                  <ChartComponent chartData={memoizedHouseholdSizeData} />
                </div>
              )}

              {/* Household Income charts */}
              {memoizedIncomeData && (
                <>
                  <div className="chart-wrapper">
                    <ChartComponent chartData={memoizedIncomeData.owners} />
                  </div>
                  <div className="chart-wrapper">
                    <ChartComponent chartData={memoizedIncomeData.renters} />
                  </div>
                </>
              )}

              {/* Mobility by Tenure chart */}
              {memoizedMobilityByTenureData && (
                <div className="chart-wrapper">
                  <ChartComponent chartData={memoizedMobilityByTenureData} />
                </div>
              )}
              {/* Mobility by Age chart */}
              {memoizedMobilityData && (
                <div className="chart-wrapper">
                  <ChartComponent chartData={memoizedMobilityData} />
                </div>
              )}
              {/* Mobility by Income chart */}
              {memoizedMobilityByIncomeData && (
                <div className="chart-wrapper">
                  <ChartComponent chartData={memoizedMobilityByIncomeData} />
                </div>
              )}

              {/* Display any errors */}
              {demographicData.errors && demographicData.errors.length > 0 && (
                <div className="errors-section">
                  {demographicData.errors.map((error, index) => (
                    <div key={index} className="error-message">{error}</div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocalDemographics; 