import React, { useEffect, useState } from 'react';
import api from '../api';
import ChartComponent from './ChartComponent';
import './SupplyAndDemand.css';
import { CircularProgress } from '@mui/material';

const SupplyAndDemand = ({ selectedLocation }) => {
  const [supplyDemandChart, setSupplyDemandChart] = useState(null);
  const [scatterChart, setScatterChart] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchSupplyAndDemandData = async () => {
      if (!selectedLocation) return;
      
      setIsLoading(true);
      try {
        const response = await api.get('/get-supply-and-demand-data', {
          params: { locationId: selectedLocation },
        });
        
        if (response.data.charts) {
          // Format the supply and demand chart data
          const supplyDemandData = {
            chart_name: 'supply_and_demand_chart',
            display_name: 'Supply and Demand',
            ...response.data.charts.supply_demand_chart
          };
          
          // Add the callback function for y-axis ticks
          if (supplyDemandData.options?.scales?.y?.ticks) {
            supplyDemandData.options.scales.y.ticks.callback = function(value) {
              return value === 0 ? '0' : value < 1 ? value.toFixed(1) : Math.round(value);
            };
          }
          
          // Format the scatter plot data
          const scatterData = {
            chart_name: 'scatter_plot',
            display_name: 'Price vs Square Footage',
            ...response.data.charts.scatter_plot
          };
          
          // Add the callback function for y-axis ticks in scatter plot
          if (scatterData.options?.scales?.y?.ticks) {
            scatterData.options.scales.y.ticks.callback = function(value) {
              return '$' + (Math.round(value/1000)).toLocaleString() + 'K';
            };
          }
          
          setSupplyDemandChart(supplyDemandData);
          setScatterChart(scatterData);
        }
      } catch (error) {
        console.error('Error fetching supply and demand data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSupplyAndDemandData();
  }, [selectedLocation]);

  if (!selectedLocation) {
    return <div className="supply-demand-content">Please select a location to view supply and demand data.</div>;
  }

  return (
    <div className="supply-demand-content">
      <div className="supply-demand-charts-container">
        {isLoading ? (
          <div className="loading-container">
            <CircularProgress size={40} style={{ color: '#E26313' }} />
            <p>Loading supply and demand data...</p>
          </div>
        ) : (
          <>
            {supplyDemandChart && (
              <div className="supply-demand-chart-wrapper">
                <h3>{supplyDemandChart.display_name}</h3>
                <ChartComponent chartData={supplyDemandChart} />
              </div>
            )}
            {scatterChart && (
              <div className="supply-demand-chart-wrapper">
                <h3>{scatterChart.display_name}</h3>
                <ChartComponent chartData={scatterChart} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SupplyAndDemand; 