import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MarketOverview from './MarketOverview';
import ClusteredListings from './clustered-listings/ClusteredListings';
import MacroEconMap from './MacroEconMap';
import ZipCodeMap from './ZipCodeMap';
import LocalDemographics from './LocalDemographics';
import './Home.css';
import api from '../api';
import { CircularProgress } from '@mui/material';
import ChatBox from './ChatBox';
import SupplyAndDemand from './SupplyAndDemand';

const Home = ({ 
  user, 
  selectedLocation, 
  selectedDistance, 
  selectedClient, 
  selectedSection, 
  onSectionChange,
  locations
}) => {
  const [locationInfo, setLocationInfo] = useState(null);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);

  // location info contains  
  // "latitude"
  // "longitude"
  // "zip_code"
  // "fips_code"
  // "city_name"
  // "state_abbreviation"
  // "county_name"
  // "population"
  // "density"

  // Fetch coordinates when selectedLocation changes
  useEffect(() => {
    let isMounted = true;
    const fetchLocationInfo = async () => {
      if (!selectedLocation) {
        setLocationInfo(null);
        return;
      }
      
      setIsLoadingLocation(true);
      try {
        const response = await api.get('/api/get-location-details', {
          params: { locationId: selectedLocation },
        });
        if (isMounted) {  // Only update state if component is still mounted
          console.log('Received location info:', response.data);
          setLocationInfo(response.data);
        }
      } catch (err) {
        console.error('Error fetching location info:', err);
        if (isMounted) {
          setLocationInfo(null);
        }
      } finally {
        if (isMounted) {
          setIsLoadingLocation(false);
        }
      }
    };

    fetchLocationInfo();
    
    return () => {
      isMounted = false;  // Cleanup function
    };
  }, [selectedLocation]);

  return (
    <div className="home-container">
      {user ? (
        <>
          <div className="content">
            <div className="section-buttons">
              <button 
                onClick={() => onSectionChange('overview')} 
                className={selectedSection === 'overview' ? 'active' : ''}
              >
                Market Overview
              </button>
              <button 
                onClick={() => onSectionChange('cluster')} 
                className={selectedSection === 'cluster' ? 'active' : ''}
              >
                Listings Evaluator
              </button>
              <button 
                onClick={() => onSectionChange('demographics')} 
                className={selectedSection === 'demographics' ? 'active' : ''}
              >
                Local Demographics
              </button>
              <button 
                onClick={() => onSectionChange('zipcode')} 
                className={selectedSection === 'zipcode' ? 'active' : ''}
              >
                Zip Code Map
              </button>
              <button 
                onClick={() => onSectionChange('macro')} 
                className={selectedSection === 'macro' ? 'active' : ''}
              >
                Macro Economic Index
              </button>
              <button 
                onClick={() => onSectionChange('supplyDemand')} 
                className={selectedSection === 'supplyDemand' ? 'active' : ''}
              >
                Supply and Demand
              </button>
            </div>

            {/* Show loading message under buttons */}
            {isLoadingLocation && (
              <div className="loading-message">
                <CircularProgress size={60} style={{ color: '#E26313' }} />
              </div>
            )}

            {/* Render the selected section */}
            {!isLoadingLocation && (
              <>
                {selectedSection === 'overview' && (
                  <MarketOverview 
                    selectedLocation={selectedLocation}
                    locationInfo={locationInfo}
                    selectedDistance={selectedDistance}
                  />
                )}
                {selectedSection === 'cluster' && locationInfo && (
                  <ClusteredListings 
                    selectedLocation={selectedLocation} 
                    selectedDistance={selectedDistance} 
                    selectedClient={selectedClient}
                    locationInfo={locationInfo}
                  />
                )}
                {selectedSection === 'supplyDemand' && (
                  <SupplyAndDemand selectedLocation={selectedLocation} />
                )}
                {selectedSection === 'demographics' && (
                  <LocalDemographics 
                    selectedLocation={selectedLocation}
                    locationInfo={locationInfo}
                    selectedDistance={selectedDistance}
                  />
                )}
                {selectedSection === 'macro' && locationInfo && (
                  <MacroEconMap 
                    selectedDistance={selectedDistance} 
                    selectedLocation={selectedLocation}
                    locationInfo={locationInfo}
                  />
                )}
                {selectedSection === 'zipcode' && locationInfo && (
                  <ZipCodeMap 
                    selectedLocation={selectedLocation}
                    locationInfo={locationInfo}
                  />
                )}
                {(selectedSection === 'macro' || selectedSection === 'zipcode') && !locationInfo && !isLoadingLocation && (
                  <div className="loading-message">
                    <p>Please select a location to view this section.</p>
                  </div>
                )}
              </>
            )}
          </div>
          <ChatBox 
            userId={user.id} 
            locations={locations}
          />
        </>
      ) : (
        <div className="home-container">
          <p>Please log in or sign up to see your application.</p>
          <div className="auth-buttons">
            <Link to="/login">
              <button className="auth-button btn btn-primary">Login</button>
            </Link>
            <Link to="/sign-up">
              <button className="auth-button btn btn-primary">Sign Up</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
