import React, { useEffect, useState, useCallback, useRef } from 'react';
import api from '../api';
import './MarketOverview.css';
import { CircularProgress } from '@mui/material';
import StatsHighlight from './StatsHighlight';

const MarketOverview = ({ selectedLocation, locationInfo, isLoadingListings, selectedDistance }) => {
  const [permitData, setPermitData] = useState(null);
  const [listingsData, setListingsData] = useState(null);
  const [activePendingData, setActivePendingData] = useState(null);
  const [marketOverviewData, setMarketOverviewData] = useState(null);

  const fetchInProgressRef = useRef({
    permits: false,
    listings: false,
    population: false,
    activePending: false,
    marketOverview: false
  });

  // Separate loading states for different sections
  const [loadingStates, setLoadingStates] = useState({
    permits: true,
    listings: true,
    population: true,
    activePending: true,
    marketOverview: true
  });

  // Replace the single isLoading state with this function
  const updateLoadingState = (key, value) => {
    setLoadingStates(prev => ({ ...prev, [key]: value }));
  };

  const fetchPermitData = useCallback(async () => {
    if (fetchInProgressRef.current.permits || !locationInfo?.fips_code) return;
    fetchInProgressRef.current.permits = true;

    try {
      // Fetch both permit data sets in parallel
      const [permitTableResponse, singleCountyResponse] = await Promise.all([
        api.get('/get-county-permit-table-and-info', {
          params: { 'county-fips': locationInfo.fips_code },
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }),
        api.get('/get_permits_for_single_county_query', {
          params: { 'county-fips': locationInfo.fips_code },
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        })
      ]);

      // Combine both responses into the permitData state
      setPermitData({
        ...permitTableResponse.data,
        chart_js_json: singleCountyResponse.data
      });
    } catch (error) {
      console.error('Error fetching permit data:', error);
      setPermitData(null);
    } finally {
      fetchInProgressRef.current.permits = false;
      updateLoadingState('permits', false);
    }
  }, [locationInfo?.fips_code]);

  const fetchListingsData = useCallback(async () => {
    if (fetchInProgressRef.current.listings || !locationInfo?.fips_code) return;
    fetchInProgressRef.current.listings = true;

    try {
        const response = await api.post('/get-listings-updates-new', {
            location_id: selectedLocation,
            reference_coord: [locationInfo.latitude, locationInfo.longitude],
            search_distance: selectedDistance
        });

        // Directly use the response data without extracting three_miles or five_miles
        if (!response.data) {
            console.warn('Response missing required data structure:', response.data);
            setListingsData(null);
            return;
        }

        // Use the data directly from the response
        const processedData = {
            time_stamp: response.data.time_stamp,
            scatter_plot: response.data.scatter_plot,
            listings_stats: response.data.listings_stats
        };

        setListingsData(processedData);

    } catch (error) {
        console.error('Error fetching listings data:', error);
        setListingsData(null);
    } finally {
        fetchInProgressRef.current.listings = false;
        updateLoadingState('listings', false);
    }
  }, [
    locationInfo?.fips_code,
    locationInfo?.latitude,
    locationInfo?.longitude,
    selectedLocation,
    selectedDistance
  ]);

  const fetchActivePendingData = useCallback(async () => {
    if (fetchInProgressRef.current.activePending || !locationInfo?.zip_code) return;
    fetchInProgressRef.current.activePending = true;

    try {
      const response = await api.get('/get-active-pending-listings-by-zip', {
        params: { 'zip-code': locationInfo.zip_code },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setActivePendingData(response.data);
    } catch (error) {
      console.error('Error fetching active/pending listings data:', error);
      setActivePendingData(null);
    } finally {
      fetchInProgressRef.current.activePending = false;
      updateLoadingState('activePending', false);
    }
  }, [locationInfo?.zip_code]);

  const fetchMarketOverview = useCallback(async () => {
    if (fetchInProgressRef.current.marketOverview || !selectedLocation) return;
    fetchInProgressRef.current.marketOverview = true;

    try {
      const response = await api.get('/get-market-overview', {
        params: { location_id: selectedLocation },
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setMarketOverviewData(response.data);
    } catch (error) {
      console.error('Error fetching market overview:', error);
      setMarketOverviewData(null);
    } finally {
      fetchInProgressRef.current.marketOverview = false;
      updateLoadingState('marketOverview', false);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (!locationInfo?.fips_code) return;

    // Reset loading states when location changes
    setLoadingStates({
      permits: true,
      listings: true,
      population: true,
      activePending: true,
      marketOverview: true
    });

    // Fetch each data type independently
    fetchPermitData();
    fetchListingsData();
    fetchActivePendingData();
    fetchMarketOverview();
  }, [locationInfo, fetchPermitData, fetchListingsData, fetchActivePendingData, fetchMarketOverview]);

  if (loadingStates.permits || 
      loadingStates.listings || 
      loadingStates.activePending || 
      (loadingStates.marketOverview && marketOverviewData !== null)) {
    return (
      <div className="overview-container">
        <div className="loading-container">
          <CircularProgress size={60} style={{ color: '#E26313' }} />
        </div>
      </div>
    );
  }

  if (!locationInfo) {
    return (
      <div className="overview-container">
        <div className="loading-container">
          <CircularProgress size={60} style={{ color: '#E26313' }} />
        </div>
      </div>
    );
  }

  // Remove the currentListingsData variable and debug log
  console.log('Debug listingsData:', {
    hasData: !!listingsData,
    stats: listingsData?.listings_stats,
    selectedDistance
  });

  return (
    <div className="overview-wrapper">
      <StatsHighlight 
        locationInfo={locationInfo} 
        permitData={permitData} 
        listingsData={listingsData}
        activePendingData={activePendingData}
        marketOverviewData={marketOverviewData}
      />
      <div className="overview-grid">
      </div>
    </div>
  );
};

export default MarketOverview;
