import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

/**
 * RegularClusterCharts
 *
 * Renders the "Price vs Sq Ft", "Price vs Days on Market", "Price Distribution",
 * and "Monthly Sales" charts. Each chart is wrapped in its own styled container.
 */
const RegularClusterCharts = ({ data }) => {
  console.log('RegularClusterCharts received data:', data);

  const priceDistRef = useRef(null);
  const sqftRef = useRef(null);
  const daysRef = useRef(null);
  const monthlyRef = useRef(null);

  useEffect(() => {
    // Clear any existing charts
    const clearCharts = () => {
      [priceDistRef, sqftRef, daysRef, monthlyRef].forEach(ref => {
        if (ref.current?.chart) {
          ref.current.chart.destroy();
          ref.current.chart = null;
        }
      });
    };

    clearCharts();

    // Only proceed if we have valid data
    if (!data) {
      console.log('No data provided to RegularClusterCharts');
      return;
    }

    console.log('Creating charts with data:', {
      priceDist: Boolean(data.price_distribution_chart),
      monthly: Boolean(data.monthly_sales_chart),
      sqft: Boolean(data.price_to_sq_ft_scatter),
      days: Boolean(data.price_to_days_scatter)
    });

    // Create new charts if we have the data
    try {
      if (data.price_distribution_chart && priceDistRef.current) {
        console.log('Creating price distribution chart');
        priceDistRef.current.chart = new Chart(priceDistRef.current, {
          ...data.price_distribution_chart,
          options: {
            ...data.price_distribution_chart.options,
            responsive: true,
            maintainAspectRatio: false
          }
        });
      }

      if (data.monthly_sales_chart && monthlyRef.current) {
        console.log('Creating monthly sales chart');
        monthlyRef.current.chart = new Chart(monthlyRef.current, {
          ...data.monthly_sales_chart,
          options: {
            ...data.monthly_sales_chart.options,
            responsive: true,
            maintainAspectRatio: false
          }
        });
      }

      if (data.price_to_sq_ft_scatter && sqftRef.current) {
        console.log('Creating price vs sqft chart');
        sqftRef.current.chart = new Chart(sqftRef.current, {
          ...data.price_to_sq_ft_scatter,
          options: {
            ...data.price_to_sq_ft_scatter.options,
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              ...data.price_to_sq_ft_scatter.options?.plugins,
              tooltip: {
                callbacks: {
                  label: function(context) {
                    const point = context.raw;
                    const pricePerSqFt = point.y / point.x;
                    return [
                      `${context.dataset.label}`,
                      `Price: $${point.y.toLocaleString()}`,
                      `Sqft: ${point.x.toLocaleString()}`,
                      `Price/Sqft: $${pricePerSqFt.toFixed(2)}`
                    ];
                  }
                }
              }
            }
          }
        });
      }

      if (data.price_to_days_scatter && daysRef.current) {
        console.log('Creating price vs days chart');
        daysRef.current.chart = new Chart(daysRef.current, {
          ...data.price_to_days_scatter,
          options: {
            ...data.price_to_days_scatter.options,
            responsive: true,
            maintainAspectRatio: false
          }
        });
      }
    } catch (error) {
      console.error('Error creating charts:', error);
    }

    // Cleanup function
    return clearCharts;
  }, [data]); // Only re-run if data changes

  // Define a common card style for each chart container.
  const chartCardStyle = {
    width: 'calc(50% - 10px)',
    height: '300px',
    backgroundColor: 'white',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  };

  return (
    <div>
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        padding: '20px'
      }}>
        {/* Price Distribution Chart */}
        <div style={chartCardStyle}>
          <canvas ref={priceDistRef}></canvas>
        </div>
        
        {/* Monthly Sales Chart */}
        <div style={chartCardStyle}>
          <canvas ref={monthlyRef}></canvas>
        </div>
        
        {/* Price vs Sq Ft Chart */}
        <div style={chartCardStyle}>
          <canvas ref={sqftRef}></canvas>
        </div>
        
        {/* Price vs Days Chart */}
        <div style={chartCardStyle}>
          <canvas ref={daysRef}></canvas>
        </div>
      </div>
    </div>
  );
};

export default RegularClusterCharts; 