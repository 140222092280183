import React, { useState } from 'react';
import './ComponentDescription.css';

const ComponentDescription = ({ title, description, features }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="component-description-container">
      <div className="description-header" onClick={() => setIsExpanded(!isExpanded)}>
        <div className="description-content">
          <p>The <strong>{title}</strong> {description}</p>
          <p className="see-more-info">To see more information about the tool, click anywhere on this text box.</p>
        </div>
        <span className={`dropdown-arrow ${isExpanded ? 'expanded' : ''}`}>▼</span>
      </div>
      
      {isExpanded && (
        <div className="description-details">
          <ul className="features-list">
            {features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ComponentDescription; 