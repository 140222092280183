// src/components/ZipCodeMap.jsx

import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import api from '../api';
import 'mapbox-gl/dist/mapbox-gl.css'; // Import Mapbox GL CSS
import './ZipCodeMap.css'; // Ensure this path is correct
import { CircularProgress } from '@mui/material';
import './ComponentDescription.css';

// Import the existing ChartComponent
import ChartComponent from './ChartComponent';

// Import PropTypes for type checking (optional but recommended)
import PropTypes from 'prop-types';

// Set your Mapbox access token from environment variables for security
mapboxgl.accessToken = 'pk.eyJ1IjoidGtpbTcxMSIsImEiOiJjbHpyNTlrc2owbnduMmxweWNwMDVwdWlyIn0.Giqxjm8xnxmEiDyDQJtXbg';

// Define chart configurations relevant to zip code data
const chartConfigs = [
  {
    id: 'zip-total-listing-count',
    title: 'Total Listing Counts',
    endpoint: '/get-zip-total-listing-count',
  },
  {
    id: 'zip_median_listing_price',
    title: 'Median Listing Price',
    endpoint: '/get-zip-median-listing-price',
  },
  {
    id: 'zip_average_listing_price',
    title: 'Average Listing Price',
    endpoint: '/get-zip-average-listing-price',
  },
  {
    id: 'zip_active_pending_ratio',
    title: 'Active to Pending Ratio',
    endpoint: '/get-zip-active-pending-ratio',
  },
  {
    id: 'zip_price_increase_vs_decrease',
    title: 'Price Increase vs Decrease',
    endpoint: '/get-price-increase-decrease',
  },
  {
    id: 'zip-median-days-on-market',
    title: 'Median Days on Market',
    endpoint: '/get-zip-median-days-on-market',
  },
  {
    id: 'zip-median-price-sqft',
    title: 'Median Price Per Square Foot',
    endpoint: '/get-zip-median-price-per-sqft',
  },
  {
    id: 'zip-median-square-feet',
    title: 'Median Square Feet',
    endpoint: '/get-zip-median-square-feet',
  },
  
  // Add more chart configurations as needed
];

const ZipCodeMap = ({ 
  selectedLocation = null, 
  locationInfo = null 
}) => {
  console.log("ZipCodeMap component is rendering."); // Basic log
  const mapContainerRef = useRef(null); // Reference to the map container div
  const mapRef = useRef(null); // To store the map instance
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedZipCode, setSelectedZipCode] = useState(null); // To store selected zip code data

  // State to manage chart data, loading, and errors for each chart
  const [charts, setCharts] = useState(
    chartConfigs.reduce((acc, chart) => {
      acc[chart.id] = { data: null, loading: false, error: null };
      return acc;
    }, {})
  );

  // Ref to store the popup instance
  const popupRef = useRef(new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    offset: 15,
  }));

  // Ref for the community marker
  const communityMarkerRef = useRef(null);

  // Add useEffect to fetch mapbox data
  const [mapConfig, setMapConfig] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false); // Add this new state

  useEffect(() => {
    const fetchMapboxData = async () => {
      try {
        const response = await api.get('/api/get-zip-code-map');
        if (response.data && response.data.mapbox_url) {
          setMapConfig(response.data);
        }
      } catch (error) {
        console.error('Error fetching mapbox data:', error);
        setError(new Error('Failed to load map configuration.'));
      }
    };

    fetchMapboxData();
  }, []);

  // Initialize the map once
  useEffect(() => {
    if (!mapContainerRef.current || !mapConfig) {
      return;
    }

    // Initialize Mapbox map with default center
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-98.5795, 39.8283],
      zoom: 4,
    });

    // Add zoom and rotation controls to the map
    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    mapRef.current.on('load', () => {
      console.log("Map has loaded successfully");
      setMapLoaded(true); // Add this line to track when map is fully loaded

      // Add the tileset as a source
      if (!mapRef.current.getSource('custom-tileset')) {
        mapRef.current.addSource('custom-tileset', {
          type: 'vector',
          url: `mapbox://${mapConfig.mapbox_url}`,
        });
        console.log("Added custom-tileset source");
      }

      // Add a layer using the source
      if (!mapRef.current.getLayer('custom-layer')) {
        console.log("Adding custom layer with 6_month_weighted_average_price coloring");
        mapRef.current.addLayer({
          id: 'custom-layer',
          type: 'fill',
          source: 'custom-tileset',
          'source-layer': mapConfig.mapbox_layer,
          paint: {
            'fill-color': [
              'case',
              ['==', ['get', '6_month_weighted_average_price'], null],
              '#cccccc', // Color for null values
              [
                'step',  // Changed from 'interpolate' to 'step' for distinct color breaks
                ['coalesce', ['to-number', ['get', '6_month_weighted_average_price']], 0],
                '#ffffcc',  // Default color for values below first step
                200000, '#c7e9b4',
                400000, '#7fcdbb',
                600000, '#41b6c4',
                800000, '#1d91c0',
                1000000, '#225ea8'
              ]
            ],
            'fill-opacity': 0.75,
            'fill-outline-color': '#ffffff'
          }
        });
      }

      // Add this after adding the layer to debug the data
      mapRef.current.on('sourcedata', (e) => {
        if (e.sourceId === 'custom-tileset' && e.isSourceLoaded) {
          const features = mapRef.current.querySourceFeatures('custom-tileset', {
            sourceLayer: mapConfig.mapbox_layer
          });
          if (features.length > 0) {
            console.log("Sample feature properties:", features[0].properties);
          }
        }
      });

      // Consolidate hover interactivity into 'mousemove'
      mapRef.current.on('mousemove', 'custom-layer', (e) => {
        if (e.features.length > 0) {
          mapRef.current.getCanvas().style.cursor = 'pointer';

          const feature = e.features[0];
          const medianPrice = feature.properties['6_month_weighted_average_price']
            ? `$${Number(feature.properties['6_month_weighted_average_price']).toLocaleString()}`
            : 'N/A';
          const zipCode = feature.properties.GEOID20
            ? feature.properties.GEOID20
            : 'N/A';

          // Set popup content and position using the single popup instance
          popupRef.current
            .setLngLat(e.lngLat)
            .setHTML(
              `<strong>Zip Code:</strong> ${zipCode}<br/>
               <strong>6 Month Weighted Average Price:</strong> ${medianPrice}`
            )
            .addTo(mapRef.current);
        } else {
          mapRef.current.getCanvas().style.cursor = '';
          popupRef.current.remove();
        }
      });

      // Handle mouse leave to remove the popup
      mapRef.current.on('mouseleave', 'custom-layer', () => {
        mapRef.current.getCanvas().style.cursor = '';
        popupRef.current.remove();
      });

      // Handle click events on the custom layer
      mapRef.current.on('click', 'custom-layer', (e) => {
        if (e.features.length > 0) {
          const feature = e.features[0];
          const zipCode = feature.properties.GEOID20
            ? feature.properties.GEOID20
            : 'N/A';
          
          console.log(`Zip Code clicked: ${zipCode}`);
          setSelectedZipCode(zipCode); // Update selected zip code
        }
      });

      setIsLoading(false); // Map loaded successfully
    });

    // Clean up on unmount
    return () => {
      if (mapRef.current) {
        console.log("Removing map instance");
        mapRef.current.remove();
        mapRef.current = null; // Reset the reference to allow re-initialization
      }
    };
  }, [mapConfig]);

  // Add community marker and zoom into it whenever locationInfo change
  useEffect(() => {
    if (!locationInfo || !mapRef.current || !mapLoaded) return; // Add mapLoaded check

    console.log("Adding community marker:", locationInfo);

    // Remove existing community marker if any
    if (communityMarkerRef.current) {
      communityMarkerRef.current.remove();
    }

    // Create and add the community marker
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: [0, 15],
      anchor: 'top',
    }).setHTML(`
      <div style="padding: 8px;">
        <strong>Your Community</strong>
      </div>
    `);

    const marker = new mapboxgl.Marker({
      color: '#E26313',
    })
      .setLngLat([locationInfo.longitude, locationInfo.latitude])
      .setPopup(popup)
      .addTo(mapRef.current);

    marker.getElement().addEventListener('mouseenter', () => popup.addTo(mapRef.current));
    marker.getElement().addEventListener('mouseleave', () => popup.remove());

    // Center the map on the marker and zoom in
    mapRef.current.flyTo({
      center: [locationInfo.longitude, locationInfo.latitude],
      zoom: 10,
      essential: true,
      duration: 1200  // Changed from 1000 to 500 milliseconds
    });

    communityMarkerRef.current = marker;

    // Cleanup function to remove the marker when locationInfo change
    return () => {
      if (communityMarkerRef.current) {
        communityMarkerRef.current.remove();
      }
    };
  }, [locationInfo, mapLoaded]); // Add mapLoaded as dependency

  // Fetch chart data whenever a zip code is selected
  useEffect(() => {
    if (!selectedZipCode) return; // No zip code selected yet

    const fetchCharts = async () => {
      // Create an array of promises for all chart fetches
      const fetchPromises = chartConfigs.map(async (chart) => {
        // Update loading state
        setCharts((prevCharts) => ({
          ...prevCharts,
          [chart.id]: { ...prevCharts[chart.id], loading: true, error: null, data: null },
        }));

        try {
          const params = { 'zip-code': selectedZipCode };

          const chartResponse = await api.get(chart.endpoint, {
            params: params,
          });

          // Set chartData to match ChartComponent's expected prop structure
          setCharts((prevCharts) => ({
            ...prevCharts,
            [chart.id]: { ...prevCharts[chart.id], data: { chart_js_json: chartResponse.data }, loading: false },
          }));
        } catch (error) {
          console.error(`Error fetching chart data for ${chart.id}:`, error);
          setCharts((prevCharts) => ({
            ...prevCharts,
            [chart.id]: {
              ...prevCharts[chart.id],
              error: error.response?.data?.error || 'Failed to load chart data.',
              loading: false,
            },
          }));
        }
      });

      // Wait for all chart fetches to complete
      await Promise.all(fetchPromises);
    };

    fetchCharts();
  }, [selectedZipCode]); // Trigger when selectedZipCode changes

  // Add this useEffect after your other useEffects
  useEffect(() => {
    if (!locationInfo?.zip_code || !mapRef.current || !mapLoaded) { // Add mapLoaded check
      console.log("Not ready to auto-select zip code:", {
        hasZipCode: !!locationInfo?.zip_code,
        hasMap: !!mapRef.current,
        isMapLoaded: mapLoaded
      });
      return;
    }

    console.log("Auto-selecting zip code:", locationInfo.zip_code);
    
    const paddedZipCode = locationInfo.zip_code.toString().padStart(5, '0');
    setSelectedZipCode(paddedZipCode);

  }, [locationInfo?.zip_code, mapLoaded]); // Add mapLoaded as dependency

  // Add a resize handler to fix map rendering issues
  useEffect(() => {
    if (!mapRef.current) return;
    
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.resize();
      }
    };
    
    window.addEventListener('resize', handleResize);
    
    // Call resize once to ensure proper initial rendering
    setTimeout(() => {
      handleResize();
    }, 100);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Remove mapRef.current from the dependency array

  return (
    <div className="container">
      <div className="zip-description-wrapper">
        <div className="component-description-container">
          <div className="description-content">
            <p>The <strong>Zip Code Map</strong> tool allows you to view detailed MLS listings activity for any zip code in the United States. This data is updated monthly to provide you with the most current market insights.</p>
            <p>You can explore different areas by scrolling around on the map and clicking on any zip code to instantly view detailed charts and metrics for that specific area.</p>
          </div>
        </div>
      </div>
      
      <div className="zip-content-wrapper">
        <div className="map-container" ref={mapContainerRef}>
          {isLoading && (
            <div className="loading-overlay" style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000
            }}>
              <div className="loading-content" style={{
                textAlign: 'center'
              }}>
                <CircularProgress size={40} style={{ color: '#E26313' }} />
                <p style={{ marginTop: '10px', color: '#666' }}>Loading map...</p>
              </div>
            </div>
          )}
          {error && (
            <div className="overlay error">
              <p>Error loading map: {error.message}</p>
            </div>
          )}
        </div>
        <div className="table-container">
          {selectedZipCode && (
            <div className="county-info">
              <h3>Selected Zip Code: {selectedZipCode.toString().padStart(5, '0')}</h3>

              <div className="chart-sections">
                {chartConfigs.map((chart) => (
                  <div key={chart.id} className="chart-container">
                    <h4>{chart.title}</h4>
                    {charts[chart.id].loading && <p>Loading chart...</p>}
                    {charts[chart.id].error && <p className="error">Error loading chart: {charts[chart.id].error}</p>}
                    {charts[chart.id].data && <ChartComponent chartData={charts[chart.id].data} />}
                  </div>
                ))}
              </div>
            </div>
          )}
          {!selectedZipCode && (
            <div className="county-info">
              <p>Click on a zip code to view detailed data and charts.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ZipCodeMap.propTypes = {
  selectedLocation: PropTypes.string,
  locationInfo: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
};

export default ZipCodeMap;